import styled from 'styled-components'
import { Paper } from '@77sol/core'

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 10px;
`

export const PaperIcon = styled(Paper)`
  width: 48px;
  height: 48px;
  padding: 0;

  display: grid;
  place-content: center;
`

export const TextIcon = styled.span`
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0.16px;
  text-transform: uppercase;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 10px;
  }
`
