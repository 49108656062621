/* eslint-disable import/no-cycle */
import React, { useState, useEffect } from 'react'
import { Card, Typography } from 'components'
import { Spacer, Button, useToast } from '@77sol/core'
import Tooltip from '@material-ui/core/Tooltip'
import { Grid, Hidden, IconButton, useMediaQuery } from '@material-ui/core'
import palette from 'app_palette'
import CircularProgress from '@material-ui/core/CircularProgress'
import moment from 'moment'
import Backdrop from '@material-ui/core/Backdrop'
import { useDispatch } from 'react-redux'
import {
  ArrowChevronUpIcon,
  ArrowChevronForwardIcon,
  ActionPlusIcon,
  SaveFavoriteBookmarkIcon,
  DownloadIcon,
  ViewIcon,
  DeleteIcon,
  FilledCloseIcon,
  EditIcon,
  WeatherLightningIcon,
  SolarPanelIcon,
  RoomServiceHelpIcon,
  FilledSaveFavoriteBookmark,
  BagIcon,
  CircleActionsAlertInfoIcon,
} from '@77sol/icons/dist'
import { pulse } from 'react-animations'
import * as FileSaver from 'file-saver'
import Radium, { StyleRoot } from 'radium'
import { currentEnterpriseConfigs } from 'globalConfigs/validationEnterprise'
import { getAllCustomers } from 'utils/requests'
import API from 'api'
import { handlePotency, parseBRL } from 'utils'
import * as ProposalAction from 'store/actions/index'
import { useAmplitude } from 'hooks/useAmplitude'
import { usePlan } from 'hooks/usePlan'
import { proposalTracker } from 'services/tracker/events/proposal/trackers'
import ModalSizingAlert from '../ModalSizingAlert'
import { QuotationDeleteModal } from 'containers/Modals/QuotationDeleteModal'
import { useDeleteQuotation } from './hooks/useDeleteQuotation'
import { ModalRemoveSizing } from '..'
import { useStyles } from './styles'
import { useCheckoutModalStore } from 'store/modals/CheckoutModalStore'
import { useValidateDeleteQuotation } from 'hooks/quotation/useValidateDeleteQuotation'

moment.locale('pt-br')

const styles = {
  bounce: {
    animation: 'x 1s',
    animationName: Radium.keyframes(pulse, 'bounce'),
    animationIterationCount: 'infinite',
  },
}

function SizingCard({
  index,
  proposal,
  quoteEdit,
  setQuoteEdit,
  setConfigEditing,
  handleNewSizing,
  handleCreateQuote,
  setSizingModal,
  sizing,
  configEditing,
  structureLabel,
  expandedQuotesBySizing,
  setExpandedQuotesBySizing,
  loadingCreateQuote,
  quotePanelEditing,
  setQuotePanelEditing,
  isFromProposalList,
  setEditSnackbarActive,
  highlightedButton,
}) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [clients, setClients] = useState([])

  const upXl = useMediaQuery((theme) => theme.breakpoints.up('xl'))
  const downSm = useMediaQuery((theme) => theme.breakpoints.down('sm'))

  const { quotationDelete, handleCloseDeleteModal, handleOpenDeleteModal } =
    useDeleteQuotation()

  const { checkSingleQuotation } = useValidateDeleteQuotation({
    quotation: quotationDelete,
    allQuotations: proposal.quotes,
  })

  const [openLoadingPdf, setOpenLoadingPdf] = useState()
  const [showAlertDiferency, setShowAlertDiferency] = useState(false)

  const [alertQuotation, setAlertQuotation] = useState({})

  const [loadingUpdateKitPotency, setLoadingUpdateKitPotency] = useState(false)
  const [loadingChangePotency, setLoadingChangePotency] = useState(false)
  const [hideAlert, setHideAlert] = useState(false)

  const [modalDeleteSizing, setModalDeleteSizing] = useState(false)
  const [sizingToDelete, setSizingToDelete] = useState()

  const { onSuccessOpenToast, onErrorOpenToast } = useToast()

  const successMessageRemoveSizing = () => {
    onSuccessOpenToast('Dimensionamento removido com sucesso!')
  }

  const filterAddedItems = (kit, addedItems) => {
    const excludeItems = JSON.parse(addedItems)
    const fitteredItems = kit.filter(
      (product) => !excludeItems.includes(product.id),
    )
    return fitteredItems
  }

  const handleSave = (id, favorited) => {
    API.put(`/proposal/quotation/favorite?quotation_id=${id}`, {
      quotation_id: id,
    })
      .then(() => {
        handleFinish(id)
        onSuccessOpenToast(
          `Cotação ${favorited ? 'desfavoritada' : 'favoritada'} com sucesso!`,
        )
      })
      .catch(() => {
        onErrorOpenToast(
          `Não foi possível ${
            favorited ? 'desfavoritar' : 'favoritar'
          } cotação!`,
        )
      })
  }

  const handleFinish = (id) => {
    API.post('/proposal/quotation/budget/finalized', {
      quotation_id: id,
    }).then((res) => {
      dispatch(ProposalAction.UpdateQuoteDataRedux(res.data.quotation))
    })
  }

  const handlePdf = (quote) => {
    setOpenLoadingPdf(true)
    API.get(`/admin/pdf-generate/quotation/${quote.quotation.id}`, {
      headers: {
        responseType: 'blob',
      },
      responseType: 'blob',
    })
      .then((res) => {
        const file = new Blob([res.data], { type: 'application/pdf' })
        FileSaver.saveAs(
          file,
          `${quote.quotation.id}_Cotacao_${handlePotency(
            quote.quotation.added_items
              ? filterAddedItems(quote.kit, quote.quotation.added_items)
              : quote.kit,
          )
            .toString()
            .replace('.', '_')}kWp_${clients
            .find((client) => client.id == proposal.proposal.customer_id)
            ?.label?.split(' ')
            .join('_')}_${moment(new Date()).format('L')}`,
        )
        setOpenLoadingPdf(false)
      })
      .catch(() => {
        setOpenLoadingPdf(false)
      })
  }

  const { logEvent } = useAmplitude()

  const checkoutModal = useCheckoutModalStore((state) => state)

  const handleUpdateCart = (id) => {
    logEvent(proposalTracker.actions.reserveEquipment)
    checkoutModal.setQuotationId(id)
    checkoutModal.setIsOpen(true)
  }

  const getClients = async () => {
    const allCustomers = await getAllCustomers()

    dispatch(ProposalAction.SaveClientsRedux(allCustomers))
    setClients(allCustomers)

    return allCustomers
  }

  const handleUpdateKitPotency = (value, quoteId) => {
    setLoadingUpdateKitPotency(true)

    const finalValue = parseFloat(value)

    API.post('/proposal/quotation/update/kit', {
      quotation_id: quoteId,
      potency: finalValue,
    })
      .then((res) => {
        setShowAlertDiferency(false)
        dispatch(ProposalAction.UpdateQuoteRedux(res.data))
        onSuccessOpenToast('Cotação atualizada com sucesso!')
      })
      .catch(() => {
        onErrorOpenToast('Não foi possível alterar a cotação!')
      })
      .finally(() => setLoadingUpdateKitPotency(false))
  }

  const handleChangePotency = (value) => {
    setLoadingChangePotency(true)

    const finalValue = parseFloat(value)

    API.post('/proposal/dimensioning/update/potency', {
      dimensioning_id: alertQuotation.dimensioning.id,
      potency: finalValue,
    })
      .then((res) => {
        dispatch(ProposalAction.UpdateSizingRedux(res.data))
        onSuccessOpenToast('Dimensionamento atualizado com sucesso!')
        setShowAlertDiferency(false)
      })
      .catch(() => {
        onErrorOpenToast('Não foi possível alterar o dimensionamento!')
      })
      .finally(() => setLoadingChangePotency(false))
  }

  useEffect(() => {
    if (proposal && proposal.clients.length) {
      setClients(proposal.clients)
    } else {
      getClients()
    }
  }, [])

  const { isPro } = usePlan()

  const handleAddSizing = () => {
    if (isPro()) {
      handleNewSizing()
    }
  }

  return (
    <StyleRoot>
      <Card
        title={
          <Typography
            type={downSm || !upXl ? 'link_medium' : 'text_large'}
            color="primary"
            colorWeight="300"
          >
            {`Dimensionamento ${index + 1}`}
          </Typography>
        }
        bold
        headerChildren={
          <div>
            {downSm ? (
              <div style={{ display: 'flex' }}>
                {index === 0 && (
                  <>
                    <Tooltip title="Novo dimensionamento">
                      <IconButton
                        aria-label="Novo dimensionamento"
                        onClick={() => handleAddSizing()}
                        size="small"
                      >
                        <ActionPlusIcon
                          width="24px"
                          height="24px"
                          color={palette.grayscale[700]}
                        />
                      </IconButton>
                    </Tooltip>
                    <Spacer size="8" direction="vertical" />
                  </>
                )}
                <Tooltip title="Editar dimensionamento">
                  <IconButton
                    aria-label="Editar dimensionamento"
                    onClick={() => setSizingModal(sizing)}
                    size="small"
                  >
                    <EditIcon
                      width="24px"
                      height="24px"
                      color={palette.grayscale[700]}
                    />
                  </IconButton>
                </Tooltip>
              </div>
            ) : (
              <div style={{ display: 'flex' }}>
                {index === 0 && (
                  <div>
                    <Hidden lgDown>
                      {quoteEdit ? (
                        <Tooltip title="Novo dimensionamento">
                          <IconButton
                            aria-label="Novo dimensionamento"
                            onClick={() => handleAddSizing()}
                          >
                            <ActionPlusIcon
                              width="24px"
                              height="24px"
                              color={palette.grayscale[700]}
                            />
                          </IconButton>
                        </Tooltip>
                      ) : (
                        <Button
                          variant="outlined"
                          size="small"
                          noMargin
                          onClick={() => handleAddSizing()}
                          style={{ minWidth: 'max-content' }}
                        >
                          <div
                            style={{ display: 'flex', alignItems: 'center' }}
                          >
                            <ActionPlusIcon
                              width="16px"
                              color={palette.primary[300]}
                            />
                            <Spacer direction="vertical" size="12" />
                            Criar novo
                          </div>
                        </Button>
                      )}
                    </Hidden>
                    <Hidden xlUp>
                      <Tooltip title="Novo dimensionamento">
                        <IconButton
                          aria-label="Novo dimensionamento"
                          onClick={() => handleAddSizing()}
                        >
                          <ActionPlusIcon
                            width="24px"
                            height="24px"
                            color={palette.grayscale[700]}
                          />
                        </IconButton>
                      </Tooltip>
                    </Hidden>
                  </div>
                )}
                {!quoteEdit && (
                  <Hidden lgDown>
                    <Spacer size="16" direction="vertical" />
                  </Hidden>
                )}
                <div>
                  <Hidden lgDown>
                    {quoteEdit ? (
                      <Tooltip title="Editar dimensionamento">
                        <IconButton
                          aria-label="Editar dimensionamento"
                          onClick={() => setSizingModal(sizing)}
                        >
                          <EditIcon
                            width="24px"
                            height="24px"
                            color={palette.grayscale[700]}
                          />
                        </IconButton>
                      </Tooltip>
                    ) : (
                      <Button
                        variant="outlined"
                        size="small"
                        noMargin
                        style={{ padding: '14.5px 22px' }}
                        onClick={() => setSizingModal(sizing)}
                      >
                        Editar
                      </Button>
                    )}
                  </Hidden>
                  <Hidden xlUp>
                    <Tooltip title="Editar dimensionamento">
                      <IconButton
                        aria-label="Editar dimensionamento"
                        onClick={() => setSizingModal(sizing)}
                      >
                        <EditIcon
                          width="24px"
                          height="24px"
                          color={palette.grayscale[700]}
                        />
                      </IconButton>
                    </Tooltip>
                  </Hidden>
                </div>
                {proposal?.sizings?.length != 1 && (
                  <>
                    {!quoteEdit && (
                      <Hidden lgDown>
                        <Spacer size="16" direction="vertical" />
                      </Hidden>
                    )}
                    <div>
                      <Hidden lgDown>
                        {quoteEdit ? (
                          <Tooltip title="Excluir">
                            <IconButton
                              aria-label="Editar dimensionamento"
                              onClick={() => {
                                setModalDeleteSizing(true)
                                setSizingToDelete(sizing)
                              }}
                            >
                              <DeleteIcon
                                width="24px"
                                height="24px"
                                color={palette.red[300]}
                              />
                            </IconButton>
                          </Tooltip>
                        ) : (
                          <Button
                            variant="outlined"
                            color="red"
                            size="small"
                            noMargin
                            style={{ padding: '14.5px 22px' }}
                            onClick={() => {
                              setModalDeleteSizing(true)
                              setSizingToDelete(sizing)
                            }}
                          >
                            Excluir
                          </Button>
                        )}
                      </Hidden>
                      <Hidden xlUp>
                        <Tooltip title="Excluir">
                          <IconButton
                            aria-label="Editar dimensionamento"
                            onClick={() => {
                              setModalDeleteSizing(true)
                              setSizingToDelete(sizing)
                            }}
                          >
                            <DeleteIcon
                              width="24px"
                              height="24px"
                              color={palette.red[300]}
                            />
                          </IconButton>
                        </Tooltip>
                      </Hidden>
                    </div>
                  </>
                )}
              </div>
            )}
          </div>
        }
      >
        <Spacer size="16" direction="horizontal" />
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <div style={{ display: 'flex' }}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Typography
                type={downSm ? 'text_x_small' : 'text_small'}
                color="grayscale"
                colorWeight="700"
              >
                Potência do sistema
              </Typography>
              <Spacer size="4" direction="horizontal" />
              <div style={{ display: 'flex', alignItems: 'baseline' }}>
                <Typography
                  type={downSm ? 'link_large' : 'display_x_small_x_bold'}
                  color="grayscale"
                  colorWeight="700"
                >
                  {(sizing.potenciaCC / 1000).toFixed(2)}
                </Typography>
                <Spacer size="2" direction="vertical" />
                <Typography
                  type="text_x_small"
                  color="grayscale"
                  colorWeight="600"
                >
                  kWp
                </Typography>
              </div>
            </div>
            {!configEditing && (
              <>
                <Spacer size="32" direction="vertical" />
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography
                    type={downSm ? 'text_x_small' : 'text_small'}
                    color="grayscale"
                    colorWeight="700"
                  >
                    Média de consumo
                  </Typography>
                  <Spacer size="4" direction="horizontal" />
                  <div style={{ display: 'flex', alignItems: 'baseline' }}>
                    <Typography
                      type={downSm ? 'link_large' : 'display_x_small_x_bold'}
                      color="grayscale"
                      colorWeight="700"
                    >
                      {sizing.consumoMensalMedio.toFixed(0)}
                    </Typography>
                    <Spacer size="2" direction="vertical" />
                    <Typography
                      type="text_x_small"
                      color="grayscale"
                      colorWeight="600"
                    >
                      kWh/mês
                    </Typography>
                  </div>
                </div>
              </>
            )}
            {!configEditing && (
              <Hidden smDown>
                {isFromProposalList && !upXl ? (
                  <></>
                ) : (
                  <>
                    <Spacer size="32" direction="vertical" />
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <Typography
                        type="text_small"
                        color="grayscale"
                        colorWeight="700"
                      >
                        Local de instalação
                      </Typography>
                      <Spacer size="4" direction="horizontal" />
                      <div style={{ display: 'flex', alignItems: 'baseline' }}>
                        <Typography
                          type="display_x_small_x_bold"
                          color="grayscale"
                          colorWeight="700"
                        >
                          {structureLabel(sizing.estrutura)}
                        </Typography>
                      </div>
                    </div>
                  </>
                )}
              </Hidden>
            )}
          </div>
          <Hidden smDown>
            {expandedQuotesBySizing.findIndex((item) => item == index) != -1 ? (
              <Button
                variant="text"
                size="small"
                noMargin
                endIcon={<ArrowChevronUpIcon />}
                onClick={() => {
                  const newArray = expandedQuotesBySizing
                  const indexRemove = expandedQuotesBySizing.findIndex(
                    (item) => item == index,
                  )
                  newArray.splice(indexRemove, 1)
                  setExpandedQuotesBySizing([...newArray])
                }}
              >
                esconder cotações
              </Button>
            ) : (
              <Button
                variant="outlined"
                size="small"
                noMargin
                startIcon={<ViewIcon />}
                onClick={() => {
                  const newArray = expandedQuotesBySizing
                  newArray.push(index)
                  setExpandedQuotesBySizing([...newArray])
                }}
              >
                Ver cotações
              </Button>
            )}
          </Hidden>
        </div>
        {(expandedQuotesBySizing.findIndex((item) => item == index) != -1 ||
          downSm) && (
          <>
            <Spacer size="24" direction="horizontal" />
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                transition: 'height 0.3s ease-in-out 0s',
              }}
            >
              <Hidden smDown>
                <div style={{ padding: '8px 0px' }}>
                  {configEditing ? (
                    <Grid
                      container
                      spacing={2}
                      style={{
                        borderTop: `2px solid ${palette.grayscale[200]}`,
                      }}
                    >
                      <Grid item xs={1} />
                      <Grid
                        item
                        xs={
                          !upXl &&
                          (quotePanelEditing ||
                            (configEditing && isFromProposalList))
                            ? 3
                            : 2
                        }
                      >
                        <Typography
                          type="text_small"
                          color="grayscale"
                          colorWeight="500"
                        >
                          ID
                        </Typography>
                      </Grid>
                      {(quotePanelEditing ||
                        (configEditing && isFromProposalList)) &&
                      !upXl ? (
                        ''
                      ) : (
                        <Grid item xs={4}>
                          <Typography
                            type="text_small"
                            color="grayscale"
                            colorWeight="500"
                          >
                            {upXl ? 'Potência do kit' : 'Potência'}
                          </Typography>
                        </Grid>
                      )}
                      <Grid
                        item
                        xs={
                          !upXl &&
                          (quotePanelEditing ||
                            (configEditing && isFromProposalList))
                            ? 8
                            : 5
                        }
                      >
                        <Typography
                          type="text_small"
                          color="grayscale"
                          colorWeight="500"
                        >
                          Valor total
                        </Typography>
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid
                      container
                      spacing={2}
                      style={{
                        borderTop: `2px solid ${palette.grayscale[200]}`,
                      }}
                    >
                      <Grid item xs={1} />
                      <Grid item xs={1}>
                        <Typography
                          type="text_small"
                          color="grayscale"
                          colorWeight="500"
                        >
                          ID
                        </Typography>
                      </Grid>
                      <Hidden lgDown>
                        <Grid item xs={1}>
                          <Typography
                            type="text_small"
                            color="grayscale"
                            colorWeight="500"
                          >
                            {upXl ? 'Potência do kit' : 'Potência'}
                          </Typography>
                        </Grid>
                      </Hidden>
                      <Hidden xlUp>
                        <Grid item xs={2}>
                          <Typography
                            type="text_small"
                            color="grayscale"
                            colorWeight="500"
                          >
                            {upXl ? 'Potência do kit' : 'Potência'}
                          </Typography>
                        </Grid>
                      </Hidden>
                      {!isFromProposalList && (
                        <Grid item xs={2}>
                          <Typography
                            type="text_small"
                            color="grayscale"
                            colorWeight="500"
                          >
                            Valor equipamentos
                          </Typography>
                        </Grid>
                      )}
                      <Hidden lgDown>
                        <Grid item xs={2}>
                          <Typography
                            type="text_small"
                            color="grayscale"
                            colorWeight="500"
                          >
                            Custos extras
                          </Typography>
                        </Grid>
                      </Hidden>
                      <Grid item xs={2}>
                        <Typography
                          type="text_small"
                          color="grayscale"
                          colorWeight="500"
                        >
                          Valor total
                        </Typography>
                      </Grid>
                      <Hidden lgDown>
                        <Grid item xs={2 + (isFromProposalList ? 2 : 0)}>
                          <Typography
                            type="text_small"
                            color="grayscale"
                            colorWeight="500"
                          />
                        </Grid>
                      </Hidden>
                      <Hidden xlUp>
                        <Grid item xs={3 + (isFromProposalList ? 2 : 0)}>
                          <Typography
                            type="text_small"
                            color="grayscale"
                            colorWeight="500"
                          />
                        </Grid>
                      </Hidden>
                    </Grid>
                  )}
                </div>
              </Hidden>
              {proposal.quotes
                .filter((quote) => quote.dimensioning.id == sizing.id)
                .map((quote, i) => (
                  <>
                    {configEditing ? (
                      <div style={{ padding: '8px 0px', borderRadius: '8px' }}>
                        <Hidden smDown>
                          <Grid
                            container
                            spacing={2}
                            style={{
                              borderTop:
                                i != 0 && `2px solid ${palette.grayscale[200]}`,
                              alignItems: 'center',
                              backgroundColor:
                                quoteEdit == quote.quotation.id
                                  ? palette.primary[300]
                                  : 'transparent',
                              borderRadius: '8px',
                              padding: quoteEdit == quote.quotation.id && '8px',
                            }}
                          >
                            <Grid
                              item
                              xs={1}
                              style={{
                                justifyContent: 'center',
                                display: 'flex',
                                alignItems: 'center',
                              }}
                            >
                              <Tooltip
                                title={
                                  quote.quotation.is_favorite
                                    ? 'Desfavoritar cotação'
                                    : 'Favoritar cotação'
                                }
                              >
                                <div style={{ display: 'flex', width: 'auto' }}>
                                  {quote.quotation.is_favorite ? (
                                    <FilledSaveFavoriteBookmark
                                      style={{ cursor: 'pointer' }}
                                      onClick={() =>
                                        handleSave(
                                          quote.quotation.id,
                                          quote.quotation.is_favorite,
                                        )
                                      }
                                      width="24px"
                                      color={palette.yellow[400]}
                                    />
                                  ) : (
                                    <SaveFavoriteBookmarkIcon
                                      style={{ cursor: 'pointer' }}
                                      onClick={() =>
                                        handleSave(
                                          quote.quotation.id,
                                          quote.quotation.is_favorite,
                                        )
                                      }
                                      width="24px"
                                      color={palette.grayscale[300]}
                                    />
                                  )}
                                </div>
                              </Tooltip>
                            </Grid>
                            <Grid
                              item
                              xs={
                                !upXl &&
                                (quotePanelEditing ||
                                  (configEditing && isFromProposalList))
                                  ? 3
                                  : 2
                              }
                            >
                              <Typography
                                type="text_small"
                                color={
                                  quoteEdit == quote.quotation.id
                                    ? 'white'
                                    : 'grayscale'
                                }
                                colorWeight="700"
                              >
                                # {quote.quotation.id}
                              </Typography>
                            </Grid>
                            {(quotePanelEditing ||
                              (configEditing && isFromProposalList)) &&
                            !upXl ? (
                              ''
                            ) : (
                              <Grid item xs={4} style={{ display: 'flex' }}>
                                <Typography
                                  type="text_small"
                                  color={
                                    quoteEdit == quote.quotation.id
                                      ? 'white'
                                      : 'grayscale'
                                  }
                                  colorWeight="700"
                                >
                                  {handlePotency(
                                    quote.quotation.added_items
                                      ? filterAddedItems(
                                          quote.kit,
                                          quote.quotation.added_items,
                                        )
                                      : quote.kit,
                                  )}
                                  kWp
                                </Typography>
                                {!hideAlert &&
                                  handlePotency(
                                    quote.quotation.added_items
                                      ? filterAddedItems(
                                          quote.kit,
                                          quote.quotation.added_items,
                                        )
                                      : quote.kit,
                                  ) !=
                                    parseFloat(
                                      proposal.sizings.find(
                                        (item) =>
                                          item.id == quote.dimensioning.id,
                                      ).potenciaCC / 1000,
                                    ).toFixed(2) && (
                                    <Tooltip title="Potência desatualizada">
                                      <>
                                        <CircleActionsAlertInfoIcon
                                          width="18"
                                          color={palette.orange[300]}
                                          style={{
                                            cursor: 'pointer',
                                            marginLeft: '8px',
                                          }}
                                          onClick={() => {
                                            setAlertQuotation(quote)
                                            setShowAlertDiferency(true)
                                          }}
                                        />
                                      </>
                                    </Tooltip>
                                  )}
                              </Grid>
                            )}
                            <Grid
                              item
                              xs={
                                !upXl &&
                                (quotePanelEditing ||
                                  (configEditing && isFromProposalList))
                                  ? 8
                                  : 5
                              }
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                              }}
                            >
                              <Typography
                                type="text_small"
                                color={
                                  quoteEdit == quote.quotation.id
                                    ? 'white'
                                    : 'primary'
                                }
                                colorWeight="400"
                              >
                                {parseBRL(quote?.quotation?.total_value)}
                              </Typography>
                              {quoteEdit != quote.quotation.id &&
                              !configEditing ? (
                                <>
                                  {!quoteEdit && (
                                    <Button
                                      variant="outlined"
                                      size="small"
                                      noMargin
                                      style={{ minWidth: 'fit-content' }}
                                      onClick={() => {
                                        setQuoteEdit(quote.quotation.id)
                                        setConfigEditing('cotacao')
                                        setEditSnackbarActive(true)
                                        setQuotePanelEditing('')
                                      }}
                                    >
                                      Ver detalhes
                                    </Button>
                                  )}
                                </>
                              ) : (
                                <div className="test" style={styles.bounce}>
                                  <div
                                    style={{
                                      padding:
                                        quoteEdit &&
                                        quoteEdit != quote.quotation.id &&
                                        '8px',
                                      display: 'flex',
                                      cursor:
                                        quoteEdit &&
                                        quoteEdit != quote.quotation.id &&
                                        'pointer',
                                    }}
                                  >
                                    <ArrowChevronForwardIcon
                                      width="24px"
                                      onClick={() => {
                                        if (quoteEdit == quote.quotation.id) {
                                          if (downSm) {
                                            setQuoteEdit(quote.quotation.id)
                                            setConfigEditing('cotacao')
                                            setEditSnackbarActive(true)
                                            setQuotePanelEditing('')
                                          }
                                        } else {
                                          setQuoteEdit(quote.quotation.id)
                                          setConfigEditing('cotacao')
                                          setEditSnackbarActive(true)
                                          setQuotePanelEditing('')
                                        }
                                      }}
                                      color={
                                        quoteEdit == quote.quotation.id
                                          ? palette.white
                                          : palette.grayscale[500]
                                      }
                                    />
                                  </div>
                                </div>
                              )}
                            </Grid>
                          </Grid>
                        </Hidden>
                        <Hidden mdUp>
                          <Grid
                            container
                            spacing={2}
                            style={{
                              borderTop: `2px solid ${palette.grayscale[200]}`,
                              alignItems: 'center',
                              padding: '8px 0px',
                            }}
                          >
                            <Grid item xs={2}>
                              <Tooltip
                                title={
                                  quote.quotation.is_favorite
                                    ? 'Desfavoritar cotação'
                                    : 'Favoritar cotação'
                                }
                              >
                                <div style={{ display: 'flex', width: 'auto' }}>
                                  {quote.quotation.is_favorite ? (
                                    <FilledSaveFavoriteBookmark
                                      style={{ cursor: 'pointer' }}
                                      onClick={() =>
                                        handleSave(
                                          quote.quotation.id,
                                          quote.quotation.is_favorite,
                                        )
                                      }
                                      width="24px"
                                      color={palette.yellow[400]}
                                    />
                                  ) : (
                                    <SaveFavoriteBookmarkIcon
                                      style={{ cursor: 'pointer' }}
                                      onClick={() =>
                                        handleSave(
                                          quote.quotation.id,
                                          quote.quotation.is_favorite,
                                        )
                                      }
                                      width="24px"
                                      color={palette.grayscale[300]}
                                    />
                                  )}
                                </div>
                              </Tooltip>
                            </Grid>
                            <Grid
                              item
                              xs={8}
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                              }}
                            >
                              <div style={{ display: 'flex' }}>
                                <Typography
                                  type="text_x_small"
                                  color="grayscale"
                                  colorWeight="700"
                                >
                                  # {quote.quotation.id}
                                </Typography>
                                <Spacer size="16" direction="vertical" />
                                <Typography
                                  type="text_x_small"
                                  color="grayscale"
                                  colorWeight="700"
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                  }}
                                >
                                  <WeatherLightningIcon
                                    width="16px"
                                    color={palette.grayscale[800]}
                                    style={{ marginRight: '8px' }}
                                  />
                                  {handlePotency(
                                    quote.quotation.added_items
                                      ? filterAddedItems(
                                          quote.kit,
                                          quote.quotation.added_items,
                                        )
                                      : quote.kit,
                                  )}
                                  kWp
                                </Typography>
                                {!hideAlert &&
                                  handlePotency(
                                    quote.quotation.added_items
                                      ? filterAddedItems(
                                          quote.kit,
                                          quote.quotation.added_items,
                                        )
                                      : quote.kit,
                                  ) !=
                                    parseFloat(
                                      proposal.sizings.find(
                                        (item) =>
                                          item.id == quote.dimensioning.id,
                                      ).potenciaCC / 1000,
                                    ).toFixed(2) && (
                                    <Tooltip title="Potência desatualizada">
                                      <>
                                        <CircleActionsAlertInfoIcon
                                          width="18"
                                          color={palette.orange[300]}
                                          style={{
                                            cursor: 'pointer',
                                            marginLeft: '8px',
                                          }}
                                          onClick={() => {
                                            setAlertQuotation(quote)
                                            setShowAlertDiferency(true)
                                          }}
                                        />
                                      </>
                                    </Tooltip>
                                  )}
                              </div>
                              <div>
                                <Typography
                                  type="link_large"
                                  color="primary"
                                  colorWeight="300"
                                >
                                  {parseBRL(quote?.quotation?.total_value)}
                                </Typography>
                              </div>
                              <div>
                                <Typography
                                  type="text_x_small"
                                  color="grayscale"
                                  colorWeight="500"
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                  }}
                                >
                                  <SolarPanelIcon
                                    width="16px"
                                    color={palette.grayscale[500]}
                                    style={{ marginRight: '8px' }}
                                  />
                                  {parseBRL(
                                    parseFloat(
                                      quote?.quotation.equipment_value,
                                    ),
                                  )}
                                </Typography>
                              </div>
                            </Grid>
                            <Grid item xs={2}>
                              <div className="test" style={styles.bounce}>
                                <ArrowChevronForwardIcon
                                  width="24px"
                                  color={palette.grayscale[800]}
                                  style={{ cursor: 'pointer' }}
                                  onClick={() => {
                                    if (quoteEdit == quote.quotation.id) {
                                      if (downSm) {
                                        setQuoteEdit(quote.quotation.id)
                                        setConfigEditing('cotacao')
                                        setEditSnackbarActive(true)
                                        setQuotePanelEditing('')
                                      }
                                    } else {
                                      setQuoteEdit(quote.quotation.id)
                                      setConfigEditing('cotacao')
                                      setEditSnackbarActive(true)
                                      setQuotePanelEditing('')
                                    }
                                  }}
                                />
                              </div>
                            </Grid>
                          </Grid>
                        </Hidden>
                      </div>
                    ) : (
                      <div style={{ padding: '8px 0px', borderRadius: '8px' }}>
                        <Hidden smDown>
                          <Grid
                            container
                            spacing={2}
                            style={{
                              borderTop:
                                i != 0 && `2px solid ${palette.grayscale[200]}`,
                              alignItems: 'center',
                            }}
                          >
                            <Grid
                              item
                              xs={1}
                              style={{ justifyContent: 'center' }}
                            >
                              <Tooltip
                                title={
                                  quote.quotation.is_favorite
                                    ? 'Desfavoritar cotação'
                                    : 'Favoritar cotação'
                                }
                              >
                                <div style={{ display: 'flex', width: 'auto' }}>
                                  {quote.quotation.is_favorite ? (
                                    <FilledSaveFavoriteBookmark
                                      style={{ cursor: 'pointer' }}
                                      onClick={() =>
                                        handleSave(
                                          quote.quotation.id,
                                          quote.quotation.is_favorite,
                                        )
                                      }
                                      width="24px"
                                      color={palette.yellow[400]}
                                    />
                                  ) : (
                                    <SaveFavoriteBookmarkIcon
                                      style={{ cursor: 'pointer' }}
                                      onClick={() =>
                                        handleSave(
                                          quote.quotation.id,
                                          quote.quotation.is_favorite,
                                        )
                                      }
                                      width="24px"
                                      color={palette.grayscale[300]}
                                    />
                                  )}
                                </div>
                              </Tooltip>
                            </Grid>
                            <Grid item xs={1}>
                              <Typography
                                type="text_small"
                                color="grayscale"
                                colorWeight="700"
                              >
                                # {quote.quotation.id}
                              </Typography>
                            </Grid>
                            <Hidden lgDown>
                              <Grid item style={{ display: 'flex' }} xs={1}>
                                <Typography
                                  type="text_small"
                                  color="grayscale"
                                  colorWeight="700"
                                >
                                  {handlePotency(
                                    quote.quotation.added_items
                                      ? filterAddedItems(
                                          quote.kit,
                                          quote.quotation.added_items,
                                        )
                                      : quote.kit,
                                  )}
                                  kWp
                                </Typography>
                                {!hideAlert &&
                                  handlePotency(
                                    quote.quotation.added_items
                                      ? filterAddedItems(
                                          quote.kit,
                                          quote.quotation.added_items,
                                        )
                                      : quote.kit,
                                  ) !=
                                    parseFloat(
                                      proposal.sizings.find(
                                        (item) =>
                                          item.id == quote.dimensioning.id,
                                      ).potenciaCC / 1000,
                                    ).toFixed(2) && (
                                    <Tooltip title="Potência desatualizada">
                                      <>
                                        <CircleActionsAlertInfoIcon
                                          width="18"
                                          color={palette.orange[300]}
                                          style={{
                                            cursor: 'pointer',
                                            marginLeft: '8px',
                                          }}
                                          onClick={() => {
                                            setAlertQuotation(quote)
                                            setShowAlertDiferency(true)
                                          }}
                                        />
                                      </>
                                    </Tooltip>
                                  )}
                              </Grid>
                            </Hidden>
                            <Hidden xlUp>
                              <Grid item style={{ display: 'flex' }} xs={2}>
                                <Typography
                                  type="text_small"
                                  color="grayscale"
                                  colorWeight="700"
                                >
                                  {handlePotency(
                                    quote.quotation.added_items
                                      ? filterAddedItems(
                                          quote.kit,
                                          quote.quotation.added_items,
                                        )
                                      : quote.kit,
                                  )}
                                  kWp
                                </Typography>
                                {!hideAlert &&
                                  handlePotency(
                                    quote.quotation.added_items
                                      ? filterAddedItems(
                                          quote.kit,
                                          quote.quotation.added_items,
                                        )
                                      : quote.kit,
                                  ) !=
                                    parseFloat(
                                      proposal.sizings.find(
                                        (item) =>
                                          item.id == quote.dimensioning.id,
                                      ).potenciaCC / 1000,
                                    ).toFixed(2) && (
                                    <Tooltip title="Potência desatualizada">
                                      <>
                                        <CircleActionsAlertInfoIcon
                                          width="18"
                                          color={palette.orange[300]}
                                          style={{
                                            cursor: 'pointer',
                                            marginLeft: '8px',
                                          }}
                                          onClick={() => {
                                            setAlertQuotation(quote)
                                            setShowAlertDiferency(true)
                                          }}
                                        />
                                      </>
                                    </Tooltip>
                                  )}
                              </Grid>
                            </Hidden>
                            {!isFromProposalList && (
                              <Grid item xs={2}>
                                <Typography
                                  type="text_small"
                                  color="grayscale"
                                  colorWeight="700"
                                >
                                  {parseBRL(
                                    parseFloat(
                                      quote?.quotation.equipment_value,
                                    ),
                                  )}
                                </Typography>
                              </Grid>
                            )}
                            <Hidden lgDown>
                              <Grid item xs={2}>
                                <Typography
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                  }}
                                  type="text_small"
                                  color="grayscale"
                                  colorWeight="700"
                                >
                                  <FilledCloseIcon
                                    color={palette.primary[300]}
                                    width="20px"
                                    style={{
                                      transform: 'rotate(45deg)',
                                      marginRight: '10px',
                                      minWidth: 20,
                                      cursor: 'pointer',
                                    }}
                                    onClick={() => {
                                      setQuoteEdit(quote.quotation.id)
                                      setConfigEditing('cotacao')
                                      setEditSnackbarActive(true)
                                      setQuotePanelEditing('budget')
                                    }}
                                  />
                                  {parseBRL(
                                    parseFloat(quote?.quotation.total_value) -
                                      parseFloat(
                                        quote?.quotation.equipment_value,
                                      ),
                                  )}
                                </Typography>
                              </Grid>
                            </Hidden>
                            <Grid
                              item
                              xs={2}
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                              }}
                            >
                              <Typography
                                type="text_small"
                                color="primary"
                                colorWeight="400"
                              >
                                {parseBRL(quote?.quotation?.total_value)}
                              </Typography>
                            </Grid>
                            <Hidden xlUp>
                              <Grid
                                item
                                xs={4 + (isFromProposalList ? 2 : 0)}
                                style={{
                                  display: 'flex',
                                  justifyContent: 'flex-end',
                                  alignItems: 'center',
                                }}
                              >
                                <div
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'flex-end',
                                  }}
                                >
                                  {(!quote?.quotation?.filter_stock ||
                                    quote?.quotation?.filter_stock ==
                                      '77sol') && (
                                    <>
                                      <Tooltip
                                        title={
                                          quote?.quotation?.reserved
                                            ? currentEnterpriseConfigs.orderGeneratedText
                                            : currentEnterpriseConfigs.textToGenerateOrder
                                        }
                                      >
                                        <div style={{ display: 'flex' }}>
                                          {quote?.quotation?.reserved ? (
                                            <BagIcon
                                              width="24px"
                                              color={palette.primary[300]}
                                            />
                                          ) : (
                                            <RoomServiceHelpIcon
                                              width="24px"
                                              color={
                                                highlightedButton?.quotation_id ===
                                                  quote.quotation.id &&
                                                highlightedButton?.isHighlightedButton
                                                  ? palette.primary[300]
                                                  : palette.primary[700]
                                              }
                                              style={{ cursor: 'pointer' }}
                                              onClick={() =>
                                                handleUpdateCart(
                                                  quote.quotation.id,
                                                )
                                              }
                                            />
                                          )}
                                        </div>
                                      </Tooltip>
                                      <Spacer size="24" direction="vertical" />
                                    </>
                                  )}
                                  {(!quote?.quotation?.filter_stock ||
                                    quote?.quotation?.filter_stock ==
                                      '77sol') && (
                                    <>
                                      <Tooltip title="Baixar em PDF">
                                        <div style={{ display: 'flex' }}>
                                          <DownloadIcon
                                            width="24px"
                                            color={palette.grayscale[700]}
                                            style={{ cursor: 'pointer' }}
                                            onClick={() => handlePdf(quote)}
                                          />
                                        </div>
                                      </Tooltip>
                                      <Spacer size="24" direction="vertical" />
                                    </>
                                  )}
                                  <Tooltip title="Deletar cotação">
                                    <div style={{ display: 'flex' }}>
                                      <DeleteIcon
                                        id="sizingCardSm_button_deleteQuotation"
                                        width="24px"
                                        color={palette.red[300]}
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => {
                                          handleOpenDeleteModal(quote)
                                        }}
                                      />
                                    </div>
                                  </Tooltip>
                                  <Spacer size="40" direction="vertical" />
                                </div>
                                <Button
                                  variant="outlined"
                                  size="small"
                                  noMargin
                                  style={{ minWidth: 'fit-content' }}
                                  onClick={() => {
                                    setQuoteEdit(quote.quotation.id)
                                    setConfigEditing('cotacao')
                                    setEditSnackbarActive(true)
                                    setQuotePanelEditing('')
                                  }}
                                >
                                  Ver detalhes
                                </Button>
                              </Grid>
                            </Hidden>
                            <Hidden lgDown>
                              <Grid
                                item
                                xs={3 + (isFromProposalList ? 2 : 0)}
                                style={{
                                  display: 'flex',
                                  justifyContent: 'flex-end',
                                  alignItems: 'center',
                                }}
                              >
                                <div
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'flex-end',
                                  }}
                                >
                                  {(!quote?.quotation?.filter_stock ||
                                    quote?.quotation?.filter_stock ==
                                      '77sol') && (
                                    <>
                                      <Tooltip
                                        title={
                                          quote?.quotation?.reserved
                                            ? currentEnterpriseConfigs.orderGeneratedText
                                            : currentEnterpriseConfigs.textToGenerateOrder
                                        }
                                      >
                                        <div style={{ display: 'flex' }}>
                                          {quote?.quotation?.reserved ? (
                                            <BagIcon
                                              width="24px"
                                              color={palette.primary[300]}
                                            />
                                          ) : (
                                            <RoomServiceHelpIcon
                                              width="24px"
                                              color={
                                                highlightedButton?.quotation_id ===
                                                  quote.quotation.id &&
                                                highlightedButton?.isHighlightedButton
                                                  ? palette.primary[300]
                                                  : palette.grayscale[700]
                                              }
                                              style={{ cursor: 'pointer' }}
                                              onClick={() =>
                                                handleUpdateCart(
                                                  quote.quotation.id,
                                                )
                                              }
                                            />
                                          )}
                                        </div>
                                      </Tooltip>
                                      <Spacer size="24" direction="vertical" />
                                    </>
                                  )}
                                  {(!quote?.quotation?.filter_stock ||
                                    quote?.quotation?.filter_stock ==
                                      '77sol') && (
                                    <>
                                      <Tooltip title="Baixar em PDF">
                                        <div style={{ display: 'flex' }}>
                                          <DownloadIcon
                                            width="24px"
                                            color={palette.grayscale[700]}
                                            style={{ cursor: 'pointer' }}
                                            onClick={() => handlePdf(quote)}
                                          />
                                        </div>
                                      </Tooltip>
                                      <Spacer size="24" direction="vertical" />
                                    </>
                                  )}
                                  <Tooltip title="Deletar cotação">
                                    <div style={{ display: 'flex' }}>
                                      <DeleteIcon
                                        width="24px"
                                        color={palette.red[300]}
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => {
                                          handleOpenDeleteModal(quote)
                                        }}
                                      />
                                    </div>
                                  </Tooltip>
                                  <Spacer size="40" direction="vertical" />
                                </div>
                                <Button
                                  variant="outlined"
                                  size="small"
                                  noMargin
                                  style={{ minWidth: 'fit-content' }}
                                  onClick={() => {
                                    setQuoteEdit(quote.quotation.id)
                                    setConfigEditing('cotacao')
                                    setEditSnackbarActive(true)
                                    setQuotePanelEditing('')
                                  }}
                                >
                                  Ver detalhes
                                </Button>
                              </Grid>
                            </Hidden>
                          </Grid>
                        </Hidden>
                        <Hidden mdUp>
                          <Grid
                            container
                            spacing={2}
                            style={{
                              borderTop: `2px solid ${palette.grayscale[200]}`,
                              alignItems: 'center',
                              padding: '8px 0px',
                            }}
                          >
                            <Grid item xs={2}>
                              {quote.quotation.is_favorite ? (
                                <FilledSaveFavoriteBookmark
                                  style={{ cursor: 'pointer' }}
                                  onClick={() =>
                                    handleSave(
                                      quote.quotation.id,
                                      quote.quotation.is_favorite,
                                    )
                                  }
                                  width="24px"
                                  color={palette.yellow[400]}
                                />
                              ) : (
                                <SaveFavoriteBookmarkIcon
                                  style={{ cursor: 'pointer' }}
                                  onClick={() =>
                                    handleSave(
                                      quote.quotation.id,
                                      quote.quotation.is_favorite,
                                    )
                                  }
                                  width="24px"
                                  color={palette.grayscale[300]}
                                />
                              )}
                            </Grid>
                            <Grid
                              item
                              xs={8}
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                              }}
                            >
                              <div style={{ display: 'flex' }}>
                                <Typography
                                  type="text_x_small"
                                  color="grayscale"
                                  colorWeight="700"
                                >
                                  # {quote.quotation.id}
                                </Typography>
                                <Spacer size="16" direction="vertical" />
                                <Typography
                                  type="text_x_small"
                                  color="grayscale"
                                  colorWeight="700"
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                  }}
                                >
                                  <WeatherLightningIcon
                                    width="16px"
                                    color={palette.grayscale[800]}
                                    style={{ marginRight: '8px' }}
                                  />
                                  {handlePotency(
                                    quote.quotation.added_items
                                      ? filterAddedItems(
                                          quote.kit,
                                          quote.quotation.added_items,
                                        )
                                      : quote.kit,
                                  )}
                                  kWp
                                </Typography>
                                {!hideAlert &&
                                  handlePotency(
                                    quote.quotation.added_items
                                      ? filterAddedItems(
                                          quote.kit,
                                          quote.quotation.added_items,
                                        )
                                      : quote.kit,
                                  ) !=
                                    parseFloat(
                                      proposal.sizings.find(
                                        (item) =>
                                          item.id == quote.dimensioning.id,
                                      ).potenciaCC / 1000,
                                    ).toFixed(2) && (
                                    <Tooltip title="Potência desatualizada">
                                      <>
                                        <CircleActionsAlertInfoIcon
                                          width="18"
                                          color={palette.orange[300]}
                                          style={{
                                            cursor: 'pointer',
                                            marginLeft: '8px',
                                          }}
                                          onClick={() => {
                                            setAlertQuotation(quote)
                                            setShowAlertDiferency(true)
                                          }}
                                        />
                                      </>
                                    </Tooltip>
                                  )}
                              </div>
                              <div>
                                <Typography
                                  type="link_large"
                                  color="primary"
                                  colorWeight="300"
                                >
                                  {parseBRL(quote?.quotation?.total_value)}
                                </Typography>
                              </div>
                              <div>
                                <Typography
                                  type="text_x_small"
                                  color="grayscale"
                                  colorWeight="500"
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                  }}
                                >
                                  <SolarPanelIcon
                                    width="16px"
                                    color={palette.grayscale[500]}
                                    style={{ marginRight: '8px' }}
                                  />
                                  {parseBRL(
                                    parseFloat(
                                      quote?.quotation.equipment_value,
                                    ),
                                  )}
                                </Typography>
                              </div>
                            </Grid>
                            <Grid item xs={2}>
                              <div className="test" style={styles.bounce}>
                                <ArrowChevronForwardIcon
                                  width="24px"
                                  color={palette.grayscale[800]}
                                  style={{ cursor: 'pointer' }}
                                  onClick={() => {
                                    if (quoteEdit == quote.quotation.id) {
                                      if (downSm) {
                                        setQuoteEdit(quote.quotation.id)
                                        setConfigEditing('cotacao')
                                        setEditSnackbarActive(true)
                                        setQuotePanelEditing('')
                                      }
                                    } else {
                                      setQuoteEdit(quote.quotation.id)
                                      setConfigEditing('cotacao')
                                      setQuotePanelEditing('')
                                      setEditSnackbarActive(true)
                                    }
                                  }}
                                />
                              </div>
                            </Grid>
                          </Grid>
                        </Hidden>
                      </div>
                    )}
                  </>
                ))}
            </div>
            <center
              style={{ borderTop: `2px solid ${palette.grayscale[200]}` }}
            >
              <Button
                id="sizingCard_button_newQuotation"
                variant="text"
                size="small"
                noMargin
                isLoading={loadingCreateQuote}
                startIcon={<ActionPlusIcon />}
                onClick={() => {
                  if (isPro()) {
                    handleCreateQuote(sizing, false)
                  }
                }}
              >
                Nova cotação
              </Button>
            </center>
          </>
        )}
        {modalDeleteSizing && (
          <ModalRemoveSizing
            modalDeleteSizing={modalDeleteSizing}
            setModalDeleteSizing={setModalDeleteSizing}
            sizingToDelete={sizingToDelete}
            setSizingToDelete={setSizingToDelete}
            index={index}
            structureLabel={structureLabel}
            callback={successMessageRemoveSizing}
            proposal={proposal}
          />
        )}
        {showAlertDiferency && (
          <ModalSizingAlert
            showAlertDiferency={showAlertDiferency}
            setShowAlertDiferency={setShowAlertDiferency}
            alertQuotation={alertQuotation}
            proposal={proposal}
            loadingUpdateKitPotency={loadingUpdateKitPotency}
            loadingChangePotency={loadingChangePotency}
            setHideAlert={setHideAlert}
            handleUpdateKitPotency={handleUpdateKitPotency}
            handleChangePotency={handleChangePotency}
          />
        )}

        {quotationDelete && (
          <QuotationDeleteModal
            quotation={quotationDelete}
            onClose={handleCloseDeleteModal}
            onSuccess={handleCloseDeleteModal}
            onStopDelete={checkSingleQuotation}
          />
        )}
        <Backdrop className={classes.backdrop} open={openLoadingPdf}>
          <CircularProgress style={{ marginBottom: '20px' }} color="inherit" />
          Carregando PDF
        </Backdrop>
      </Card>
    </StyleRoot>
  )
}

export default SizingCard
