import { type IModalWrapperProps } from '@77sol-ui/molecules'
import { Modal } from 'components/Modal'
import { Button } from '@77sol-ui/atoms'
import { Landmark } from 'lucide-react'
import { FormManager } from '../FormManager'
import {
  type DefaultValuesForm,
  type ICreateFinancingPerValueFormProps,
} from '../FormManager/types'
import { FormProvider } from 'react-hook-form'
import { useCreateFinancingPerValueForm } from '../FormManager/hooks/useCreateFinancingPerValueForm'
import { useState } from 'react'
import { useAcl } from 'acl/hooks/useAcl'
import { StyledModalContent, StyledModalDescriptionWrapper } from './styles'
import { useAmplitude } from 'hooks/useAmplitude'
import { financingTracker } from 'services/tracker/events/financing/trackers'
import { InsuranceAlert } from 'components/InsuranceAlert'

interface IContentProps extends IModalWrapperProps {
  onCreateFinancing: ICreateFinancingPerValueFormProps['onSubmit']
  onCloseModal: () => void
  requestFallback: DefaultValuesForm
}

export function Content({
  onCreateFinancing,
  onCloseModal,
  requestFallback,
  ...props
}: IContentProps) {
  const { userType } = useAcl()
  const { logEvent } = useAmplitude()
  const methods = useCreateFinancingPerValueForm(requestFallback)
  const [isOpenInsuranceAlert, setIsOpenInsuranceAlert] =
    useState<boolean>(false)
  const [isEnabledInsurance, setIsEnabledInsurance] = useState<boolean>(
    userType === 'integrator',
  )

  function handleControlInsuranceAlert() {
    const { openInsuranceAlert, closeInsuranceAlert } = financingTracker.actions

    const newAlertState = !isOpenInsuranceAlert
    const tracker = newAlertState ? openInsuranceAlert : closeInsuranceAlert

    logEvent(tracker, {
      financingType: 'Valor',
    })

    setIsOpenInsuranceAlert(newAlertState)
  }

  function handleControlInsurance() {
    const { enableInsurance, disableInsurance } = financingTracker.actions
    const newState = !isEnabledInsurance

    const tracker = newState ? enableInsurance : disableInsurance

    logEvent(tracker, {
      financingType: 'Valor',
    })

    setIsEnabledInsurance(newState)
  }

  const totalFinancingValue = methods.watch('total_value')
  const clientType = methods.watch('client_type')

  return (
    <FormProvider {...methods}>
      <Modal.Wrapper
        id="create-financing-modal-wrapper"
        maxWidth="790px"
        maxHeight="90vh"
        {...props}
      >
        <Modal.Close onClick={onCloseModal} />
        <Modal.Header>
          <Modal.Icon icon={Landmark} />
          <Modal.Title>Crie uma simulação de financiamento</Modal.Title>
          <StyledModalDescriptionWrapper>
            <Modal.Description>
              Adicione as informações necessárias para criar uma simulação de
              financiamento.
              <InsuranceAlert.Trigger
                isOpen={isOpenInsuranceAlert}
                clientType={clientType}
                onClick={handleControlInsuranceAlert}
                isEnabledInsurance={isEnabledInsurance}
              />
            </Modal.Description>
          </StyledModalDescriptionWrapper>
        </Modal.Header>

        <StyledModalContent>
          {isOpenInsuranceAlert && (
            <InsuranceAlert.Content
              clientType={clientType}
              financingValue={totalFinancingValue}
              isEnabledInsurance={isEnabledInsurance}
              handleControlInsurance={handleControlInsurance}
            />
          )}
          <FormManager
            formId="modal-financing-per-value-form"
            onSubmit={(data) => {
              onCreateFinancing({ ...data, is_insurance: isEnabledInsurance })
            }}
          />
        </StyledModalContent>

        <Modal.Footer>
          <Modal.Close asChild>
            <Button onClick={onCloseModal} fullWidth variant="outline">
              Cancelar
            </Button>
          </Modal.Close>

          <Button fullWidth type="submit" form="modal-financing-per-value-form">
            Criar simulação
          </Button>
        </Modal.Footer>
      </Modal.Wrapper>
    </FormProvider>
  )
}
