import React, { useState, useEffect } from 'react'
import { useMediaQuery } from '@material-ui/core'
import { Typography, Button, Paper, useToast, Title } from '@77sol/core'
import palette from 'app_palette'
import Modal from '@material-ui/core/Modal'
import Fade from '@material-ui/core/Fade'
import Backdrop from '@material-ui/core/Backdrop'
import { useSelector } from 'react-redux'
import { ConfigureIcon } from '@77sol/icons/dist'
import API from 'api'
import { Pro77SolTeam } from 'components/Adverts/Pro77SolTeam'
import { usePlan } from 'hooks/usePlan'
import { useStyles } from './styles'

function SaveDefaultComponent({
  openModalSavedConfigs,
  setOpenModalSavedConfigs,
  callback,
  isFromProposalEdit,
}) {
  const classes = useStyles()
  const downSm = useMediaQuery((theme) => theme.breakpoints.down('sm'))

  const [loading, setLoading] = useState(false)

  const { onSuccessOpenToast, onErrorOpenToast } = useToast()

  const { proposal } = useSelector((state) => state.Reducer1)
  const planInfos = useSelector((state) => state.ReducerProfile.planInfos)
  const userProfile = useSelector((state) => state.ReducerProfile.userProfile)

  const saveDefaultConfiguration = () => {
    setLoading(true)

    API.post('/proposal/save-default-configs', {
      proposal_id: proposal.id,
    })
      .then(() => {
        setOpenModalSavedConfigs(false)
        onSuccessOpenToast('Configurações salvas com sucesso!')
        setTimeout(() => {
          handleExitProposal()
        }, 300)
      })
      .catch(() => {
        onErrorOpenToast('Não foi possível salvar configurações!')
      })
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    if (!isFromProposalEdit) {
      setOpenModalSavedConfigs(false)
    }
  }, [])

  const handleExitProposal = () => {
    if (callback) {
      callback()
    } else {
      window.open(
        `${window.location.href.replace('/dimensionar', '')}/projetos`,
        '_self',
      )
    }
  }

  const [isSelection77Campaign, setIsSelection77Campaign] = useState(false)

  useEffect(() => {
    if (userProfile?.tags) {
      userProfile.tags.forEach((tag) => {
        if (tag.slug === 'selecao77') {
          setIsSelection77Campaign(true)
        }
      })
    }
  }, [userProfile])

  const { isPro } = usePlan()

  const handleSetToDefault = () => {
    if (isPro()) {
      saveDefaultConfiguration()
    } else setOpenModalSavedConfigs(false)
  }

  return (
    <Modal
      style={{ overflowY: downSm && 'auto', height: downSm && '100vh' }}
      className={classes.modalSm}
      open={openModalSavedConfigs}
      onClose={() => {
        setOpenModalSavedConfigs(false)
      }}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={openModalSavedConfigs} style={{ border: 'none' }}>
        <Paper style={{ height: 'auto', width: downSm ? 'auto' : '65%' }}>
          <div className={classes.context}>
            <ConfigureIcon
              style={{ marginBottom: 16, minWidth: '24px' }}
              width="40"
              color={palette.primary[300]}
            />
            <Title size="small" color="primary" colorWeight="300">
              Configurações da sua proposta
            </Title>
            <Typography>
              Deseja definir as alterações feitas nesta proposta como padrão?
            </Typography>
          </div>
          <div className={classes.actions}>
            <Button
              id={`modalSaveDefaultSizing_button_${
                isFromProposalEdit ? 'back' : 'saveAndExit'
              }`}
              onClick={() => {
                handleExitProposal()
                setOpenModalSavedConfigs(false)
              }}
              fullWidth={downSm}
              variant="outlined"
              size="small"
              noMargin
            >
              {isFromProposalEdit ? 'Ignorar' : 'Salvar e sair'}
            </Button>
            <Button
              id="modalSaveDefaultSizing_button_setToDefault"
              onClick={handleSetToDefault}
              isLoading={loading}
              size="small"
              fullWidth={downSm}
              noMargin
            >
              Definir como padrão
            </Button>
          </div>

          {isSelection77Campaign && planInfos?.pago <= 0 && (
            <Pro77SolTeam setCurrentModalClose={setOpenModalSavedConfigs}>
              <Typography>
                Quer economizar muito tempo e{' '}
                <strong>converter 10x mais</strong>?
              </Typography>
              <Typography>
                Faça parte agora da Seleção 77 e assine o 77PRO!
              </Typography>
            </Pro77SolTeam>
          )}
        </Paper>
      </Fade>
    </Modal>
  )
}

export default SaveDefaultComponent
