import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(({ breakpoints }) => ({
  boxLabels: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0 8px',
    flex: 5,
    alignItems: 'flex-start',
    justifyContent: 'center',
    textAlign: 'left',
    [breakpoints.down('md')]: {
      padding: 0,
      width: '100%',
      alignItems: 'flex-start',
    },
  },
  content: {
    display: 'flex',
    padding: '16px 0',
    alignItems: 'center',
    justifyContent: 'space-between',
    textAlign: 'left',
    flexDirection: 'column',
    [breakpoints.up('md')]: {
      flexDirection: 'row',
    },
  },
  proposalsList: {
    minHeight: '400px',
    maxHeight: '400px',
    overflow: 'hidden',
  },
}))

export default useStyles
