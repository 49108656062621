import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  root: {
    background: theme.palette.grayscale[100],
    minHeight: 'var(--app-height)',
  },
  modalSm: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}))

export default styles
