import styled, { css, type Interpolation } from 'styled-components'
import { type IFlagProps, type IFlagVariant } from './types'
import { colors } from '@77sol-ui/tokens'

export const variantStyles: Record<IFlagVariant, Interpolation<object>> = {
  blue: css`
    background-color: ${colors.blue[300]};
  `,
  orange: css`
    background-color: ${colors.orange[300]};
  `,
  green: css`
    background-color: ${colors.green[200]};
  `,
}

export const Wrapper = styled.div<Pick<IFlagProps, 'variant'>>`
  padding: 8px 10px;
  color: #fff;
  display: flex;
  align-items: center;

  > span {
    font-family: DM Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    &:not(:first-child) {
      border-left: 1px solid #fff;
      padding-left: 6px;
      margin-left: 6px;
    }
  }

  ${(props) => props.variant && variantStyles[props.variant]}
`
