import React from 'react'
import PropTypes from 'prop-types'
import { Card } from 'components'
import { Typography, Spacer, Status } from '@77sol/core'
import { Box } from '@material-ui/core'
import { Doughnut } from 'react-chartjs-2'
import styles from './styles'

const handlePercentage = (value, total) => {
  if (total === 0) {
    return 0
  }
  return parseInt((value * 100) / total)
}

const options = {
  responsive: true,
  maintainAspectRatio: false,
  cutoutPercentage: 80,
  legend: {
    display: false,
  },
  tooltips: {
    enabled: true,
    callbacks: {
      label: (context, data) => `${data.datasets[0].data[context.index]}%`,
    },
  },
}

export function MapaAtividades({ submittedProposals, acceptedProposals }) {
  const classes = styles()
  const total = submittedProposals + acceptedProposals

  const state = {
    labels: ['Enviadas', 'Aceitas'],
    datasets: [
      {
        backgroundColor: ['#063EF9', '#00CC67'],
        borderWidth: '0',
        data: [
          total === 0 ? 50 : handlePercentage(submittedProposals, total),
          total === 0 ? 50 : handlePercentage(acceptedProposals, total),
        ],
      },
    ],
  }

  return (
    <Card title="Mapa Atividades" fullHeight>
      <Box display="flex" alignItems="center" justifyContent="flex-start">
        <Box width="130px" height="130px" position="relative">
          <Doughnut data={state} options={options} width={130} height={130} />
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            position="absolute"
            top="33px"
            left="28px"
          >
            <Typography size="large">
              {handlePercentage(acceptedProposals, total)}%
            </Typography>
            <Typography size="xsmall" colorWeight="500">
              Rendimento
            </Typography>
          </Box>
        </Box>
        <Spacer size="32" direction="both" />
        <Box display="flex" alignItems="center">
          <Box className={classes.graphLegend}>
            <Typography size="xsmall" colorWeight="500">
              Propostas
            </Typography>
            <Box paddingY={1}>
              <Status label="Enviadas" ellipseColor="primary" />
              <Status label="Aceitas" ellipseColor="green" />
            </Box>
          </Box>
        </Box>
      </Box>
    </Card>
  )
}

MapaAtividades.propTypes = {
  className: PropTypes.string,
}
