import React from 'react'
import PropTypes from 'prop-types'
import { Card } from 'components'
import { Box } from '@material-ui/core'
import { Badge, Typography, Title, Spacer } from '@77sol/core'
import { parseBRL } from 'utils'
import { currentEnterpriseConfigs } from 'globalConfigs/validationEnterprise'

const badgeInfo = {
  BRONZE: {
    color: 'orange',
    label: 'Bronze',
  },
  PRATA: {
    color: 'grayscale',
    label: 'Prata',
  },
  OURO: {
    color: 'yellow',
    label: 'Ouro',
  },
  DIAMANTE: {
    color: 'secondary',
    label: 'Diamante',
  },
}

export function NivelAtual({ plan, totalDiscont, nextPlan, goalAchieved }) {
  return (
    <Card
      title="Nível Atual"
      fullHeight
      headerChildren={
        currentEnterpriseConfigs.planBadge && (
          <Badge
            label={badgeInfo[plan]?.label}
            color={badgeInfo[plan]?.color}
          />
        )
      }
    >
      <Box flex={1} justifyContent="center">
        <Title size="small" weight="xbold">
          {parseBRL(totalDiscont)}
        </Title>
        <Typography size="small" colorWeight="500">
          Valor do desconto total aplicado sobre o valor das propostas
        </Typography>
        {goalAchieved && (
          <>
            <Spacer direction="horizontal" size="16" />
            <Badge
              size="large"
              variant="contained"
              label={`Sua meta foi atingida, no próximo mês seu novo plano será ${nextPlan}!`}
              color="primary"
            />
          </>
        )}
      </Box>
    </Card>
  )
}

NivelAtual.propTypes = {
  className: PropTypes.string,
}

export default NivelAtual
