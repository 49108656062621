import { type HTMLAttributes } from 'react'
import { StyledInsuranceTrigger } from './styles'
import { ChevronDownIcon } from 'lucide-react'
import { useAcl } from 'acl/hooks/useAcl'
import { type PERSON_TYPE_ENUM } from 'enums/PersonTypeEnum'

interface IInsuranceTriggerProps extends HTMLAttributes<HTMLButtonElement> {
  isOpen: boolean
  isEnabledInsurance: boolean
  clientType: PERSON_TYPE_ENUM
}

export function InsuranceTrigger({
  isOpen = false,
  isEnabledInsurance = false,
  clientType,
  ...rest
}: IInsuranceTriggerProps) {
  const { userType } = useAcl()

  if (userType === 'distributor' || clientType === 'cnpj') {
    return (
      <StyledInsuranceTrigger {...rest} isOpen={isOpen}>
        Proteção indisponível <ChevronDownIcon />
      </StyledInsuranceTrigger>
    )
  }

  const BUTTON_TITLE = isEnabledInsurance
    ? 'Proteção inclusa'
    : 'Proteção não inclusa'

  return (
    <StyledInsuranceTrigger {...rest} isOpen={isOpen}>
      {BUTTON_TITLE} <ChevronDownIcon />
    </StyledInsuranceTrigger>
  )
}
