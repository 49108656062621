import { Header, type IHeaderRootProps } from '@77sol-ui/organisms'
import { RequestFinancingButton } from 'containers/RequestFinancingButton'
import { useHistory } from 'react-router-dom'
import { useSidebarMobileStore } from 'store/modals/SidebarMobileStore'
import { HamburgerMenu } from '../components/HamburgerMenu'
import { Notification } from 'containers/Notifications'
import { useAmplitude } from 'hooks/useAmplitude'
import { sidebarTracker } from 'services/tracker/events/sidebar/tracker'
import { notificationsTracker } from 'services/tracker/events/notifications/trackers'

export function Tablet({ ...props }: Omit<IHeaderRootProps, 'children'>) {
  const history = useHistory()
  const { logEvent } = useAmplitude()
  const setOpenSidebar = useSidebarMobileStore((state) => state.setIsOpen)

  function handleOpenSidebar() {
    logEvent(sidebarTracker.actions.expandSidebar, {
      origin: 'header',
      type: 'Button',
    })
    setOpenSidebar(true)
  }

  function handleOpenedNotifications() {
    logEvent(notificationsTracker.actions.openNotifications, {
      origin: 'header',
      type: 'Button',
    })
  }

  return (
    <Header.Root {...props}>
      <Header.Content>
        <HamburgerMenu onClick={handleOpenSidebar} />

        <Header.Logo
          onClick={() => history.push('/')}
          src="assets/logo/main/logo-77sol.svg"
        />
      </Header.Content>

      <Header.Content>
        <RequestFinancingButton />
        <Notification.Popover
          align="end"
          onOpenedSuccess={handleOpenedNotifications}
        />
      </Header.Content>
    </Header.Root>
  )
}
