import * as S from './styles'
import { Alert } from '@77sol-ui/atoms'
import { RejectModal } from './components/RejectModal'
import { useSolLeadAlert } from './hooks/useSolLeadAlert'

export function SolLeadAlert() {
  const {
    alertTexts,
    alertIsOpen,
    rejectModalIsOpen,
    handleCloseAlert,
    handleAcceptLead,
    handleRejectLeads,
    handleToggleRejectModal,
  } = useSolLeadAlert()

  if (!alertIsOpen) return

  return (
    <>
      <S.AlertContainer>
        <Alert.Root variant="info" rounded>
          <Alert.Icon variant="info" />
          <Alert.Content>
            <Alert.Title title={alertTexts.title} />
            <Alert.Description description={alertTexts.description} />
            <Alert.Button
              variant="info"
              text={alertTexts.button}
              onClick={handleAcceptLead}
            />
          </Alert.Content>
          <Alert.Close onClose={handleCloseAlert} variant="info" />
        </Alert.Root>
      </S.AlertContainer>

      {rejectModalIsOpen && (
        <RejectModal
          onReject={handleRejectLeads}
          onToggleModal={handleToggleRejectModal}
        />
      )}
    </>
  )
}
