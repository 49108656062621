interface IInsuranceInformationProps {
  value: number | string
}

export function InsuranceInformation({ value }: IInsuranceInformationProps) {
  return (
    <>
      <p>
        Selecione e garanta que o financiamento do seu cliente terá o Seguro
        Prestamista.
      </p>
      <li>
        Proteção em caso de perda de renda, invalidez permanente total por
        acidente ou em caso de morte por qualquer causa.
      </li>
      <li>
        Maiores chances de aprovação tendo em vista que seguro prestamista é uma
        garantia extra para as instituições financeiras na concessão de crédito;
      </li>
      <li>
        Acréscimo aproximado de apenas {value} na parcela do seu financiamento.
      </li>
    </>
  )
}
