import Banner from 'assets/img/communication-banners/intersolarWeb.gif'
import BannerMobile from 'assets/img/communication-banners/intersolarMobile.gif'
import * as S from './styles'

export function CommunicationBanner() {
  return (
    <S.Card>
      <picture>
        <source srcSet={BannerMobile} media="(max-width: 640px)" />
        <img src={Banner} alt="Novidade no ranking 77. Ganhe 2x mais pontos." />
      </picture>
    </S.Card>
  )
}
