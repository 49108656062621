import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(({ breakpoints }) => ({
  container: {
    height: '100%',
    display: 'flex',
    [breakpoints.up('md')]: {
      flexDirection: 'column',
    },
    alignItems: 'center',
    justifyContent: 'center',
  },
  chart: {
    width: '100%',
    [breakpoints.down('sm')]: {
      width: '50%',
    },
  },
}))

export default useStyles
