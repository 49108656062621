import { makeStyles } from '@material-ui/core'

const styles = makeStyles(({ breakpoints }) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    [breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  cardBox: {
    flex: 2,
    maxHeight: '50vh',
    [breakpoints.down('sm')]: {
      maxHeight: '100%',
    },
  },
  simulationsBox: {
    flex: 6,
    [breakpoints.down('sm')]: {
      flex: 1,
    },
  },
  requisitionsContent: {
    maxHeight: 'calc(var(--app-height) - 242px)',
    overflowY: 'auto',
  },
  requireContainer: {
    display: 'flex',
    alignItems: 'center',
    [breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  requestButtons: {
    display: 'flex',
    [breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
}))

export default styles
