import { useAcl } from 'acl/hooks/useAcl'
import {
  SIDEBAR_DESKTOP_LOGO,
  SIDEBAR_MOBILE_LOGO,
} from 'components/Sidebar/constants'
import { useAmplitude } from 'hooks/useAmplitude'
import { useSignals } from 'layouts/Dashboard/components/NavBar/components/NavigationListItem/hooks/useSignals'
import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { type INewSidebarNavMenuItems } from '../constants/integratorNavbarItems'
import { useWatchRoutes } from 'hooks/useWatchRoutes'

export function useSidebarDesktop() {
  const history = useHistory()
  const { checkIfUserCanAccessRoute } = useAcl()
  const { logEvent } = useAmplitude()
  const { signals } = useSignals()

  const [isExpanded, setIsExpanded] = useState(
    localStorage.getItem('@77sol-admin:sidebarExpanded') !== 'false',
  )

  const validatePermission = (page: INewSidebarNavMenuItems) => {
    return checkIfUserCanAccessRoute(page.href)
  }

  function onRedirect(item: INewSidebarNavMenuItems) {
    logEvent(`Menu Lateral esquerdo: ${item.title}`)
  }

  function handleControlSidebar(currentState: boolean) {
    localStorage.setItem('@77sol-admin:sidebarExpanded', String(currentState))
    setIsExpanded(currentState)
  }

  const LOGO = isExpanded ? SIDEBAR_DESKTOP_LOGO : SIDEBAR_MOBILE_LOGO

  useWatchRoutes({
    routes: ['/dashboard'],
    active: !localStorage.getItem('@77sol-admin:sidebarExpanded'),
    onAccessedTheRoute: () => {
      setIsExpanded(true)
    },
    onNoRouteAccessed: () => {
      setIsExpanded(false)
    },
  })

  return {
    isExpanded,
    validatePermission,
    onRedirect,
    handleControlSidebar,
    history,
    LOGO,
    signals,
  }
}
