export default [
  {
    id: '2',
    title: 'Enviadas',
  },
  {
    id: '3',
    title: 'Em andamento',
  },
  {
    id: '6',
    title: 'Iniciadas',
  },
  {
    id: '7',
    title: 'Finalizadas',
  },
]
