import { Portal } from 'components/Portal'
import { ChooseKitFooter } from './components/Footer'
import { KitList } from './components/KitList'
import { useEquipmentContainerStore } from 'containers/EquipmentQuotationContainer/store/EquipmentQuotationStore'
import { Banner } from './components/Banner'

export function ChooseKit() {
  const selectedKits = useEquipmentContainerStore((state) => state.selectedKits)
  return (
    <>
      <div className="tab-content">
        <Banner />
        <KitList />
      </div>

      <Portal id="quotation-container-footer">
        {selectedKits?.length > 0 && <ChooseKitFooter />}
      </Portal>
    </>
  )
}
