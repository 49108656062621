import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
  Backdrop,
  Box,
  CircularProgress,
  Hidden,
  IconButton,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import { useDispatch } from 'react-redux'

import { EmptyState, Header, Loading, TabContent, TableList } from 'components'

import {
  AutoComplete,
  Typography,
  Dropdown,
  Button,
  Spacer,
  TabList,
  Slide,
  Tooltip,
  Accordion,
  useToast,
} from '@77sol/core'

import {
  ActionPlusIcon,
  CloseXIcon,
  FilterIcon,
  FilledStarIcon,
  WeatherMoonIcon,
  ArrowUpIcon,
} from '@77sol/icons/dist'

import InfiniteScroll from 'react-infinite-scroll-component'
import palette from 'app_palette'
import { Skeleton, ToggleButton } from '@material-ui/lab'

import { ArchiveModal, DeleteModal } from 'containers/Modals'

import { ModalRemoveSizing } from 'views/DimensionarV2/components/StepFour/components'
import { SizingModal } from 'views/DimensionarV2/components'
import * as ProposalAction from 'store/actions/index'
import { getProposalStructureToEdit } from 'views/ProjectProposals'
import { currentEnterpriseConfigs } from 'globalConfigs/validationEnterprise'
import API from 'api'
import { isEmpty, formatDate } from 'utils'
import { useAmplitude } from 'hooks/useAmplitude'
import { proposalTracker } from 'services/tracker/events/proposal/trackers'
import { projectsTracker } from 'services/tracker/events/projects/trackers'
import styles from './styles'
import Icons from './Icons'
import ResponsiveCard from './ResponsiveCard'
import { deleteProposalColumns, orderOptions } from './constants'
import { ProposalListItem } from './components/ProposalListItem'
import { ProposalShareModal } from 'containers/Modals/ProposalShareModal'
import { useCheckoutModalStore } from 'store/modals/CheckoutModalStore'
import { AlertModal } from 'components/AlertModal'
import { DeleteProjectModal } from './components/DeleteProjectModal'
import { QuotationDeleteModal } from 'containers/Modals/QuotationDeleteModal'
import { useValidateDeleteQuotation } from 'hooks/quotation/useValidateDeleteQuotation'
import { Title } from 'components/Title'

function ResponsiveSlide({ desktopWidth, ...props }) {
  return (
    <>
      <Hidden smDown>
        <Slide {...props} width={desktopWidth} />
      </Hidden>
      <Hidden mdUp>
        <Slide {...props} width="100%" />
      </Hidden>
    </>
  )
}

function ProjectsContainer({
  updateQuery,
  customers,
  onDeleteProject,
  onArchiveProject,
}) {
  const { onSuccessOpenToast, onErrorOpenToast } = useToast()
  const { logEvent } = useAmplitude()
  const theme = useTheme()
  const dispatch = useDispatch()
  const downSm = useMediaQuery(theme.breakpoints.down('sm'))
  const [selectedTab, setSelectedTab] = useState(0)
  const [projectInModal, setProjectInModal] = useState('')
  const [isOrdenationOpen, setIsOrdenationOpen] = useState(false)
  const [isDescFilter, setIsDescFilter] = useState(true)
  const history = useHistory()
  const classes = styles({ isOrdenationOpen, isDescFilter })
  const [isShowProjects, setIsShowProjects] = useState(false)
  const [selectedRow, setSelectedRow] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [valueList, setValueList] = useState([])
  const [mobileValueList, setMobileValueList] = useState([])
  const [hasMoreProjects, setHasMoreProjects] = useState(true)
  const [page, setPage] = useState(1)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isRequesting, setIsRequesting] = useState(false)
  const [modalDeleteSizing, setModalDeleteSizing] = useState(false)
  const [sizingToDelete, setSizingToDelete] = useState()
  const [selectedProposalTable, setSelectedProposalTable] = useState(0)
  const [archivedStatus, setArchivedStatus] = useState(0)
  const [sizingModal, setSizingModal] = useState('')
  const [selectedCustomer, setSelectedCustomer] = useState('')
  const [projectHistory, setProjectHistory] = useState([])
  const [isHistoryModalOpen, setIsHistoryModalOpen] = useState(false)
  const [isLoadingHistoryInfo, setIsLoadingHistoryInfo] = useState(false)
  const [proposalInModal, setProposalInModal] = useState('')
  const [selectedQuote, setSelectedQuote] = useState('')
  const [openLoadingPdf, setOpenLoadingPdf] = useState()
  const [proposalList, setProposalList] = useState([])
  const [allQuotationByProposal, setAllQuotationByProposal] = useState([])
  const [customer, setCustomer] = useState('')
  const [loadingProposalInfo, setLoadingProposalInfo] = useState(false)
  const [incompletedProposal, setIncompletedProposal] = useState({})
  const [incompleteQuoteToken, setIncompleteQuoteToken] = useState('')
  const [totalQuotesIncomplete, setTotalQuotesIncomplete] = useState('')
  const [filterIncomplete, setFilterIncomplete] = useState(false)
  const [loadingContinue, setLoadingContinue] = useState(false)
  const [proposalTab, setProposalTab] = useState('regular')

  const { checkSingleQuotation } = useValidateDeleteQuotation({
    quotation: selectedQuote,
    allQuotations: allQuotationByProposal,
  })

  const checkoutModal = useCheckoutModalStore((state) => state)

  const setOpenCheckoutModal = (selectedQuoteId) => {
    checkoutModal.setQuotationId(selectedQuoteId)
    checkoutModal.setIsOpen(true)
  }

  const successMessageRemoveSizing = () => {
    refetchData(true)
    onSuccessOpenToast('Dimensionamento removido com sucesso!')
  }

  const handleNewProposal = () => {
    history.push({
      pathname: '/dimensionar',
      state: {
        type: 'new_proposal',
        projectId: selectedRow.rowData.id,
        customerId: selectedRow.rowData.customer_id,
      },
    })
  }

  const getFirstPageProjects = async () => {
    setHasMoreProjects(true)
    setIsLoading(true)
    try {
      const response = await API.get(
        `project/list?page=1&archived=${archivedStatus}&customer=${selectedCustomer}&order=${
          isDescFilter ? 'desc' : 'asc'
        }&incompletes=${filterIncomplete}`,
      )

      const mappedProjects = filterIncomplete
        ? formatTableQuotes(response.data.data)
        : formatTable(response.data.data)

      if (response.data.last_page === 1) {
        setHasMoreProjects(false)
      }
      setTotalQuotesIncomplete(response.data.totalIncomplete)
      setPage(response.data.current_page + 1)
      setValueList(mappedProjects.desktopValues)
      setMobileValueList(mappedProjects.mobileValues)
      setIsLoading(false)
    } catch (e) {
      console.log(e)
    }
  }

  async function getProjectDetails(projectId) {
    setLoadingProposalInfo(true)
    try {
      const response = await API.get(
        `project/list/proposals/${projectId}?${
          proposalTab === 'favorite' && 'favorite=1'
        }&page=${page}&incompletes=${filterIncomplete}`,
      )
      setTotalQuotesIncomplete(response.data.totalIncomplete)
      const proposals =
        proposalTab === 'regular'
          ? response.data.proposals
          : Object.values(response.data.proposals).map((item) => item)
      setProposalList(proposals)
      setLoadingProposalInfo(false)
    } catch (error) {
      setLoadingProposalInfo(false)
    }
  }

  async function getDetailsProjectWhenFavorite(projectId) {
    try {
      const response = await API.get(
        `project/list/proposals/${projectId}?${
          proposalTab === 'favorite' && 'favorite=1'
        }&page=${page}&incompletes=${filterIncomplete}`,
      )
      setProposalList(response.data.proposals)
      setLoadingProposalInfo(false)
    } catch (error) {
      setLoadingProposalInfo(false)
    }
  }

  const structureLabel = (value) => {
    const labels = {
      'fibrocimento-madeira': 'Fibrocimento (Madeira)',
      'fibrocimento-metalico': 'Fibrocimento (Metálico)',
      ceramico: 'Cerâmico',
      metalico: 'Metalico',
      laje: 'Laje',
      solo: 'Solo',
    }

    return labels[value] || 'Sem estrutura'
  }

  async function updatePage() {
    try {
      const response = await API.get(
        `project/list?page=${page}&archived=${archivedStatus}&customer=${selectedCustomer}&order=${
          isDescFilter ? 'desc' : 'asc'
        }&incompletes=${filterIncomplete}`,
      )

      const mappedProjects = filterIncomplete
        ? formatTableQuotes(response.data.data)
        : formatTable(response.data.data)

      if (response.data.last_page === page) {
        setHasMoreProjects(false)
      }
      setPage(response.data.current_page + 1)
      setValueList([...valueList, ...mappedProjects.desktopValues])
      setMobileValueList([...mobileValueList, ...mappedProjects.mobileValues])
      setIsLoading(false)
    } catch (e) {
      console.log(e)
    }
  }

  function handleIconClick(modal, row) {
    setIsModalOpen(modal)
    setProjectInModal(row)
  }

  function handleCloseModal() {
    setIsModalOpen(false)
  }

  function formatTableQuotes(data) {
    const desktopValues = data.map((project) => {
      const {
        id,
        quotations,
        lastPotency,
        updated_at,
        seller_name,
        integrator_name,
      } = project
      return {
        id: (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              textAlign: 'center',
            }}
          >
            <div>{`#${id}`}</div>
            {seller_name && (
              <>
                <Spacer size="16" direction="vertical" />
                <Tooltip
                  title={
                    integrator_name
                      ? `Feito para: ${integrator_name}`
                      : currentEnterpriseConfigs.teamName
                  }
                >
                  <div style={{ display: 'flex', width: '40px' }}>
                    <FilledStarIcon
                      width="20"
                      color={
                        integrator_name
                          ? palette.primary[300]
                          : palette.yellow[300]
                      }
                    />
                  </div>
                </Tooltip>
              </>
            )}
          </div>
        ),
        quotations,
        potency: `${Number(lastPotency).toFixed(2)}kWp`,
        updated_at: formatDate(updated_at),
        rowData: {
          ...project,
          hightlight: !customer,
        },
      }
    })

    const mobileValues = data.map((project) => {
      return {
        id: (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              textAlign: 'center',
            }}
          >
            <div>{`#${project.id}`}</div>
            {project.seller_name && (
              <>
                <Spacer size="16" direction="vertical" />
                <Tooltip
                  title={
                    project.integrator_name
                      ? `Feito para: ${project.integrator_name}`
                      : currentEnterpriseConfigs.teamName
                  }
                >
                  <div style={{ display: 'flex', width: '40px' }}>
                    <FilledStarIcon
                      width="20"
                      color={
                        project.integrator_name
                          ? palette.primary[300]
                          : palette.yellow[300]
                      }
                    />
                  </div>
                </Tooltip>
              </>
            )}
          </div>
        ),
        rowData: {
          ...project,
          hightlight: !customer,
        },
      }
    })

    return {
      mobileValues,
      desktopValues,
    }
  }

  function formatTable(data) {
    const desktopValues = data.map((project) => {
      const {
        customer,
        id,
        proposals,
        quotations,
        updated_at,
        seller_name,
        integrator_name,
      } = project
      return {
        id: (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              textAlign: 'center',
            }}
          >
            <div>{`#${id}`}</div>
            {seller_name && (
              <>
                <Spacer size="16" direction="vertical" />
                <Tooltip
                  title={
                    integrator_name
                      ? `Feito para: ${integrator_name}`
                      : currentEnterpriseConfigs.teamName
                  }
                >
                  <div style={{ display: 'flex', width: '40px' }}>
                    <FilledStarIcon
                      width="20"
                      color={
                        integrator_name
                          ? palette.primary[300]
                          : palette.yellow[300]
                      }
                    />
                  </div>
                </Tooltip>
              </>
            )}
          </div>
        ),
        customer: customer || '-',
        proposals,
        quotations,
        updated_at: formatDate(updated_at),
        rowData: {
          ...project,
          hightlight: !customer,
        },
      }
    })

    const mobileValues = data.map((project) => {
      const { customer } = project
      return {
        id: (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              textAlign: 'center',
            }}
          >
            <div>{`#${project.id}`}</div>
            {project.seller_name && (
              <>
                <Spacer size="16" direction="vertical" />
                <Tooltip
                  title={
                    project.integrator_name
                      ? `Feito para: ${project.integrator_name}`
                      : currentEnterpriseConfigs.teamName
                  }
                >
                  <div style={{ display: 'flex', width: '40px' }}>
                    <FilledStarIcon
                      width="20"
                      color={
                        project.integrator_name
                          ? palette.primary[300]
                          : palette.yellow[300]
                      }
                    />
                  </div>
                </Tooltip>
              </>
            )}
          </div>
        ),
        rowData: {
          ...project,
          hightlight: !customer,
        },
      }
    })

    return {
      mobileValues,
      desktopValues,
    }
  }

  const handleFavorite = async (quoteId) => {
    try {
      const response = await API.put(
        `/proposal/quotation/favorite?quotation_id=${quoteId}`,
      )

      const isFavorited =
        response?.data?.message === 'quotation favorite successfully'

      refetchData(true)
      onSuccessOpenToast(
        `Cotação #${quoteId} ${isFavorited ? 'favoritada' : 'desfavoritada'}`,
      )
    } catch (e) {
      onErrorOpenToast(`Não foi possivel realizar essa operação`)
    }
  }

  function successDeleteQuotation() {
    refetchData(true)
    handleCloseModal()
  }

  useEffect(() => {
    getFirstPageProjects()
  }, [archivedStatus, selectedCustomer, isDescFilter, filterIncomplete])

  function formatProposalToModal(proposal) {
    const formatedProposal = {
      id: (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            textAlign: 'center',
          }}
        >
          <div>{`#${proposal.id}`}</div>
        </div>
      ),
      dimensionings: proposal.dimensionings_count,
      quotations: proposal.quotation_total,
      rowData: proposal,
    }

    setProposalInModal(formatedProposal)
  }

  async function handleConfirmDeleteProposal(proposalToDelete) {
    try {
      await API.delete(`/proposal/delete/${proposalToDelete.rowData.id}`)

      refetchData()
      onSuccessOpenToast('Proposta excluida com sucesso!')
    } catch (e) {
      onErrorOpenToast('Erro ao excluir proposta!')
    }
  }

  function refetchData(favorite = false) {
    if (favorite) {
      getDetailsProjectWhenFavorite(selectedRow.rowData.id)
    } else {
      getFirstPageProjects()
      setIsShowProjects(false)
      setProposalInModal('')
      setIsModalOpen('')
      setSelectedQuote('')
      setSelectedRow('')
    }
  }

  const getLogs = async (page = 1) => {
    setIsHistoryModalOpen(true)
    setIsLoadingHistoryInfo(true)
    setIsShowProjects(false)

    try {
      const response = await API.get(
        `/project/logs/${selectedRow.rowData.id}?page=${page}`,
      )

      const allLogs = response.data.data

      if (response.data.last_page > page) {
        return allLogs.concat(await getLogs(page + 1))
      }

      setProjectHistory(allLogs)
      setIsLoadingHistoryInfo(false)
    } catch (e) {
      console.log(e)
    }
  }

  const handleTabChange = (value) => {
    if (archivedStatus === 0) {
      setArchivedStatus(1)
    } else {
      setArchivedStatus(0)
    }
    setSelectedTab(value)
    setPage(1)
  }

  function handleChangeTabProposal(tabIndexNumber) {
    const tabSelected = ['regular', 'favorite']
    setProposalTab(tabSelected[tabIndexNumber])
    setProposalList([])
    setPage(1)
    setSelectedProposalTable(tabIndexNumber)
  }

  useEffect(() => {
    if (selectedRow?.rowData) {
      getProjectDetails(selectedRow.rowData.id)
    }
  }, [proposalTab])

  const onConfirmDelete = async (project) => {
    setIsRequesting(true)
    await onDeleteProject(project.rowData.id)
    handleCloseModal()
    getFirstPageProjects()
    setIsRequesting(false)
  }

  const onConfirmArchive = async (type) => {
    setIsRequesting(true)
    await onArchiveProject(projectInModal.rowData.id, type)
    handleCloseModal()
    getFirstPageProjects()
    setIsRequesting(false)
  }

  function selectRow(row, isIncomplete) {
    logEvent(projectsTracker.actions.expandProject)
    if (isIncomplete) {
      setIncompleteQuoteToken(row.rowData.firstToken)
      handleIconClick('continue')
    } else {
      setLoadingProposalInfo(true)
      setProjectHistory([])
      setProposalInModal('')
      setProposalList([])
      setAllQuotationByProposal([])
      getProjectDetails(row.rowData.id)
      setSelectedRow(row)
      setIsShowProjects(true)
      setIsHistoryModalOpen(false)
    }
  }

  function seeMoreDetailsProposal(proposal, outdatedQuoteToReserve = {}) {
    setIncompletedProposal(proposal)
    if (!proposal.customer_id || proposal.completed === 0) {
      return setIsModalOpen('continue')
    }
    history.push({
      pathname: '/projetos/propostas',
      state: {
        projectId: selectedRow?.rowData?.id,
        customerId: selectedRow?.rowData?.customer_id || '',
        customerName: proposal?.customer?.nome || '',
        selectedProposalId: proposal?.id,
        outdatedQuoteToReserve,
      },
    })

    sessionStorage.setItem('@77sol:projectId', selectedRow?.rowData?.id)

    sessionStorage.setItem(
      '@77sol:customerId',
      selectedRow?.rowData?.customer_id,
    )
  }

  async function handleContinueProposal() {
    setLoadingContinue(true)
    const isProFlow =
      incompletedProposal?.dimensionings_count > 0 &&
      incompletedProposal?.quotation_total === 0
        ? 'pro'
        : 'default'

    const proposal = await getProposalStructureToEdit(
      incompleteQuoteToken || incompletedProposal?.token,
    )

    const { customer_id, project_id } = proposal.proposal

    if (!customer_id) {
      proposal.quotes.forEach((quote) => {
        dispatch(ProposalAction.SaveQuoteRedux(quote))
      })
    }

    setLoadingContinue(true)
    const findSizing = proposal.sizings.find(
      (sizing) => sizing.id === proposal.quotes[0].dimensioning.id,
    )

    return history.push({
      pathname: '/dimensionar',
      state: {
        type: `incomplete_${isProFlow}`,
        proposal: proposal.proposal,
        sizing: findSizing,
        projectId: project_id,
        customerId: customer_id,
        quotes: proposal.quotes,
      },
    })
  }

  return (
    <>
      <Backdrop
        style={{ zIndex: 1000, color: '#fff', flexDirection: 'column' }}
        open={openLoadingPdf}
      >
        <CircularProgress style={{ marginBottom: '20px' }} color="inherit" />
        Carregando PDF
      </Backdrop>
      <Box display="flex" className={classes.slide}>
        <Box
          flex={1}
          px={isShowProjects && downSm ? 0 : 4}
          style={{ width: isShowProjects && downSm ? '0' : '100%' }}
        >
          <Title text="Projetos" />
          <Hidden>
            <>
              <Box
                display="flex"
                justifyContent="space-between"
                flexWrap="wrap"
                paddingBottom={2}
                minHeight="72px"
              >
                <Box width="100%" maxWidth="350px">
                  <TabList
                    titles={['Todos', 'Arquivados']}
                    onChange={handleTabChange}
                    value={selectedTab}
                    variant="fullWidth"
                    minWidth="0px"
                  />
                </Box>
                {!isShowProjects && !isHistoryModalOpen && (
                  <>
                    <Hidden smDown>
                      <Box
                        display="flex"
                        alignItems="center"
                        width="45%"
                        minWidth="700px"
                      >
                        <AutoComplete
                          title="Buscar cliente"
                          options={customers}
                          onClear={() => setSelectedCustomer('')}
                          onSelect={(selectedCustomer) =>
                            setSelectedCustomer(selectedCustomer.id)
                          }
                        />
                        <Box
                          display="flex"
                          paddingLeft={1}
                          justifyContent="flex-end"
                          alignItems="center"
                          width="100%"
                        >
                          <Typography size="small">Ordenar por:</Typography>
                          <Spacer size="16" direction="both" />
                          <Dropdown initialValue="1" options={orderOptions} />
                          <ToggleButton
                            classes={{
                              root: classes.arrowToggleButton,
                            }}
                            disableRipple
                            value
                            selected={isDescFilter}
                            onChange={() => setIsDescFilter(!isDescFilter)}
                          >
                            <ArrowUpIcon className={classes.arrowToggleIcon} />
                          </ToggleButton>
                        </Box>
                      </Box>
                    </Hidden>
                    <Hidden mdUp>
                      <Box
                        display="flex"
                        alignItems="center"
                        paddingTop={2}
                        width="100%"
                      >
                        <Box
                          width={isOrdenationOpen ? '0px' : '100%'}
                          style={{ transition: 'ease-in-out 0.4s' }}
                        >
                          <Box display={isOrdenationOpen ? 'none' : 'flex'}>
                            <AutoComplete
                              title="Buscar cliente"
                              options={customers}
                              onClear={() =>
                                updateQuery({ name: 'customer', value: '' })
                              }
                              onSelect={(selectedCustomer) =>
                                updateQuery({
                                  name: 'customer',
                                  value: selectedCustomer.id,
                                })
                              }
                            />
                          </Box>
                        </Box>
                        <Box
                          display="flex"
                          width={isOrdenationOpen ? '100%' : 'auto'}
                          style={{ transition: 'ease-in-out 0.4s' }}
                        >
                          <ToggleButton
                            classes={{
                              root: classes.filterToggleButton,
                            }}
                            size="small"
                            disableRipple
                            value={false}
                            selected={isOrdenationOpen}
                            onChange={() =>
                              setIsOrdenationOpen(!isOrdenationOpen)
                            }
                          >
                            <FilterIcon className={classes.filterToggleIcon} />
                          </ToggleButton>
                          <Box
                            width={isOrdenationOpen ? '100%' : '0px'}
                            style={{ transition: 'ease-in-out 0.4s' }}
                          >
                            {isOrdenationOpen && (
                              <Box display="flex" width="100%">
                                <ToggleButton
                                  classes={{
                                    root: classes.arrowToggleButton,
                                  }}
                                  disableRipple
                                  value
                                  selected={isDescFilter}
                                  onChange={() =>
                                    setIsDescFilter(!isDescFilter)
                                  }
                                >
                                  <ArrowUpIcon
                                    className={classes.arrowToggleIcon}
                                  />
                                </ToggleButton>
                                <Dropdown
                                  fullWidth
                                  onChange={() => null}
                                  initialValue="1"
                                  options={orderOptions}
                                />
                                <IconButton
                                  size="small"
                                  onClick={() =>
                                    setIsOrdenationOpen(!isOrdenationOpen)
                                  }
                                >
                                  <CloseXIcon
                                    width="16px"
                                    color={palette.grayscale[800]}
                                  />
                                </IconButton>
                              </Box>
                            )}
                          </Box>
                        </Box>
                      </Box>
                    </Hidden>
                  </>
                )}
              </Box>
            </>
          </Hidden>
          {!isShowProjects && (
            <div
              onClick={() =>
                filterIncomplete
                  ? setFilterIncomplete(false)
                  : setFilterIncomplete(true)
              }
              style={{
                padding: '14px 24px',
                backgroundColor: filterIncomplete
                  ? palette.primary[300]
                  : palette.primary[100],
                display: 'flex',
                borderRadius: '8px',
                width: downSm ? '100%' : 'fit-content',
                marginBottom: '32px',
                cursor: 'pointer',
                justifyContent: downSm && 'center',
              }}
            >
              <Typography
                color={filterIncomplete ? 'white' : 'grayscale'}
                colorWeight={600}
                size="xsmall"
              >
                Cotações não finalizadas
              </Typography>
              <Spacer size="16" direction="vertical" />
              <div
                style={{
                  padding: '2px 4px',
                  backgroundColor: palette.grayscale[50],
                  borderRadius: '4px',
                  color: filterIncomplete && palette.primary[300],
                }}
              >
                {totalQuotesIncomplete || 0}
              </div>
            </div>
          )}
          <TabContent value={selectedTab} index={0}>
            <Loading isLoading={isLoading} minHeight="250px">
              <Hidden smDown>
                {!isEmpty(valueList) ? (
                  <InfiniteScroll
                    dataLength={valueList.length}
                    next={updatePage}
                    hasMore={hasMoreProjects}
                    loader={
                      hasMoreProjects && (
                        <div>
                          <p
                            style={{
                              textAlign: 'center',
                              padding: '16px',
                              marginBottom: '24px',
                            }}
                          >
                            <b> Carregando...</b>
                          </p>
                        </div>
                      )
                    }
                    height="calc(var(--app-height) - 328px)"
                  >
                    <TableList
                      columns={
                        isShowProjects || isHistoryModalOpen
                          ? ['Projeto']
                          : filterIncomplete
                          ? [
                              'Projeto',
                              'Cotações',
                              'Última potência',
                              'Última modificação',
                            ]
                          : [
                              'Projeto',
                              'Cliente',
                              'Propostas',
                              'Cotações',
                              'Última modificação',
                            ]
                      }
                      values={
                        isShowProjects || isHistoryModalOpen
                          ? mobileValueList
                          : valueList
                      }
                      onRowClick={(row) =>
                        selectRow(row, row.rowData.hightlight)
                      }
                      selectedRow={selectedRow}
                      LastItem={({ row }) =>
                        (!isShowProjects || !isShowProjects) && (
                          <Icons
                            incompleteQuote={row.rowData.hightlight}
                            onClick={(type) => handleIconClick(type, row)}
                            rowData={row.rowData}
                            setIncompleteQuoteToken={setIncompleteQuoteToken}
                            filterIncomplete={filterIncomplete}
                          />
                        )
                      }
                    />
                  </InfiniteScroll>
                ) : (
                  <EmptyState text="Oops! Nenhum projeto foi encontrado." />
                )}
              </Hidden>
              <Hidden mdUp>
                <Box>
                  {!isEmpty(valueList) ? (
                    <InfiniteScroll
                      dataLength={valueList.length}
                      next={updatePage}
                      hasMore={hasMoreProjects}
                      loader={
                        <Box height="60px">
                          <Loading isLoading="true" />
                        </Box>
                      }
                      style={{ overflowX: 'hidden' }}
                      height="calc(var(--app-height) - 365px)"
                    >
                      {valueList.map((values, i) => (
                        <ResponsiveCard
                          key={i}
                          values={values}
                          handleIconClick={handleIconClick}
                          onClick={() =>
                            selectRow(values, values.rowData.hightlight)
                          }
                          incompleteQuote={values.rowData.hightlight}
                          setIncompleteQuoteToken={setIncompleteQuoteToken}
                          filterIncomplete={filterIncomplete}
                        />
                      ))}
                    </InfiniteScroll>
                  ) : (
                    <EmptyState text="Oops! Nenhum projeto foi encontrado." />
                  )}
                </Box>
              </Hidden>
            </Loading>
          </TabContent>
          <TabContent value={selectedTab} index={1}>
            <Loading isLoading={isLoading}>
              <Hidden smDown>
                {!isEmpty(valueList) ? (
                  <InfiniteScroll
                    dataLength={valueList.length}
                    next={updatePage}
                    hasMore={hasMoreProjects}
                    loader={
                      hasMoreProjects && (
                        <div>
                          <p
                            style={{
                              textAlign: 'center',
                              padding: '16px',
                              marginBottom: '24px',
                            }}
                          >
                            <b> Carregando...</b>
                          </p>
                        </div>
                      )
                    }
                    height="calc(var(--app-height) - 328px)"
                  >
                    <TableList
                      columns={
                        isShowProjects || isHistoryModalOpen
                          ? ['Projeto']
                          : filterIncomplete
                          ? [
                              'Projeto',
                              'Cotações',
                              'Última potência',
                              'Última modificação',
                            ]
                          : [
                              'Projeto',
                              'Cliente',
                              'Propostas',
                              'Cotações',
                              'Última modificação',
                            ]
                      }
                      values={
                        isShowProjects || isHistoryModalOpen
                          ? mobileValueList
                          : valueList
                      }
                      onRowClick={(row) =>
                        selectRow(row, row.rowData.hightlight)
                      }
                      selectedRow={selectedRow}
                      LastItem={({ row }) =>
                        (!isShowProjects || !isShowProjects) && (
                          <>
                            <Icons
                              incompleteQuote={row.rowData.hightlight}
                              onClick={(type) => handleIconClick(type, row)}
                              rowData={row.rowData}
                              setIncompleteQuoteToken={setIncompleteQuoteToken}
                              filterIncomplete={filterIncomplete}
                              archived
                            />
                          </>
                        )
                      }
                    />
                  </InfiniteScroll>
                ) : (
                  <EmptyState text="Oops! Nenhum projeto foi encontrado." />
                )}
              </Hidden>
              <Hidden mdUp>
                <Box>
                  {!isEmpty(valueList) ? (
                    <InfiniteScroll
                      dataLength={valueList.length}
                      next={updatePage}
                      hasMore={hasMoreProjects}
                      loader={
                        <Box height="60px">
                          <Loading isLoading="true" />
                        </Box>
                      }
                      style={{ overflowX: 'hidden' }}
                      height="calc(var(--app-height) - 365px)"
                    >
                      {valueList.map((values, i) => (
                        <ResponsiveCard
                          key={i}
                          values={values}
                          onClick={() =>
                            selectRow(values, values.rowData.hightlight)
                          }
                          incompleteQuote={values.rowData.hightlight}
                          setIncompleteQuoteToken={setIncompleteQuoteToken}
                          handleIconClick={handleIconClick}
                          filterIncomplete={filterIncomplete}
                          archived
                        />
                      ))}
                    </InfiniteScroll>
                  ) : (
                    <EmptyState text="Oops! Nenhum projeto foi encontrado." />
                  )}
                </Box>
              </Hidden>
            </Loading>
          </TabContent>
        </Box>
        <ResponsiveSlide isOpen={isHistoryModalOpen} desktopWidth="40%">
          <Header
            onBack={() => {
              setIsHistoryModalOpen(false)
              setIsShowProjects(true)
              setProjectHistory([])
            }}
            sticky
            noMargin
            title="Histórico geral"
          />
          <Box padding={2}>
            {isLoadingHistoryInfo && (
              <>
                <Skeleton key={Math.random()} height="56px" width="100%" />
                <Skeleton key={Math.random()} height="56px" width="100%" />
              </>
            )}
            {!isLoadingHistoryInfo && projectHistory.length === 0 && (
              <EmptyState text="Não há histórico desse projeto" />
            )}
            <InfiniteScroll
              dataLength={projectHistory.length}
              next={null}
              hasMore={false}
              loader={
                <div>
                  <p
                    style={{
                      textAlign: 'center',
                      padding: '16px',
                      marginBottom: '24px',
                    }}
                  >
                    <b> Carregando...</b>
                  </p>
                </div>
              }
              height="calc(var(--app-height) - 240px)"
            >
              {projectHistory.map((history) => (
                <>
                  <Accordion
                    title={
                      <Box
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          width: '100%',
                        }}
                      >
                        <Box style={{ maxWidth: '230px' }}>
                          <Typography size="small" colorWeight="800">
                            {history.action}
                          </Typography>
                        </Box>
                        <Box>
                          <Typography size="xsmall" colorWeight="400">
                            {formatDate(history.updated_at)}
                          </Typography>
                        </Box>
                      </Box>
                    }
                  >
                    <Box style={{ display: 'flex', flexDirection: 'column' }}>
                      <Typography
                        size="xsmall"
                        color="primary"
                        colorWeight="300"
                      >
                        Alteração feita por: {history.user_name}
                      </Typography>
                      <Typography
                        size="xsmall"
                        color="grayscale"
                        colorWeight="400"
                      >
                        {history.after_state}
                      </Typography>
                    </Box>
                  </Accordion>
                  <Spacer size="12" direction="horizontal" />
                </>
              ))}
            </InfiniteScroll>
          </Box>
        </ResponsiveSlide>
        <ResponsiveSlide
          isOpen={isShowProjects}
          desktopWidth="90%"
          id="project-slide"
        >
          <Header
            onBack={() => {
              setIsShowProjects(false)
              setSelectedRow('')
              setProjectHistory([])
              setProposalInModal('')
              setProposalList([])
              setAllQuotationByProposal([])
            }}
            noMargin
            title={`Projeto #${selectedRow?.rowData?.id} - ${
              selectedRow?.rowData?.customer || ''
            }`}
          >
            {!downSm ? (
              <div style={{ display: 'flex' }}>
                <Button
                  size="small"
                  variant="outlined"
                  onClick={() => getLogs()}
                >
                  Abrir histórico
                </Button>
                <Spacer size="16" direction="vertical" />
                <Button size="small" onClick={handleNewProposal}>
                  Nova proposta
                </Button>
              </div>
            ) : (
              <Tooltip title="Nova proposta">
                <div>
                  <ActionPlusIcon
                    onClick={handleNewProposal}
                    color="white"
                    width="20px"
                    style={{ cursor: 'pointer' }}
                  />
                </div>
              </Tooltip>
            )}
          </Header>
          <Box padding={2}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: downSm && 'column',
              }}
            >
              <Box width="100%" maxWidth={downSm ? '100%' : '350px'}>
                <TabList
                  titles={['Todos', 'Favoritos']}
                  onChange={handleChangeTabProposal}
                  value={selectedProposalTable}
                  variant="fullWidth"
                />
              </Box>
              {downSm && <Spacer size="20" direction="horizontal" />}
            </div>
            <Spacer size="18" direction="horizontal" />
            <div>
              {loadingProposalInfo && (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    width: '100%',
                    height: '400px',
                    justifyContent: 'center',
                  }}
                >
                  <Spacer size="32" direction="horizontal" />
                  <Typography color="grayscale" colorWeight="400">
                    Carregando propostas...
                  </Typography>
                </div>
              )}

              {proposalList.length === 0 && !loadingProposalInfo && (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    width: '100%',
                    height: '400px',
                    justifyContent: 'center',
                  }}
                >
                  <WeatherMoonIcon width="24" color={palette.grayscale[400]} />
                  <Spacer size="12" direction="vertical" />
                  <Typography color="grayscale" colorWeight="400">
                    Nenhuma proposta encontrada
                  </Typography>
                </div>
              )}
              {proposalList?.map((proposal, index) => (
                <ProposalListItem
                  key={proposal.id}
                  proposal={proposal}
                  index={index}
                  setSelectedQuote={setSelectedQuote}
                  setOpenCheckoutModal={setOpenCheckoutModal}
                  seeMoreDetailsProposal={seeMoreDetailsProposal}
                  setOpenLoadingPdf={setOpenLoadingPdf}
                  selectedRow={selectedRow}
                  setIsModalOpen={setIsModalOpen}
                  setSizingModal={setSizingModal}
                  formatProposalToModal={formatProposalToModal}
                  setAllQuotationByProposal={setAllQuotationByProposal}
                  handleFavorite={handleFavorite}
                />
              ))}
            </div>
          </Box>
        </ResponsiveSlide>
      </Box>
      {isModalOpen === 'delete' && (
        <DeleteProjectModal
          isOpen
          onClose={handleCloseModal}
          project={projectInModal?.rowData}
          onContinue={() => onConfirmDelete(projectInModal)}
          isLoading={isRequesting}
        />
      )}
      {modalDeleteSizing && (
        <ModalRemoveSizing
          modalDeleteSizing={modalDeleteSizing}
          setModalDeleteSizing={setModalDeleteSizing}
          sizingToDelete={sizingToDelete}
          setSizingToDelete={setSizingToDelete}
          index={0}
          structureLabel={structureLabel}
          callback={successMessageRemoveSizing}
          proposal={{ quotes: allQuotationByProposal }}
        />
      )}
      {isModalOpen === 'deleteProposal' && (
        <DeleteModal
          columns={deleteProposalColumns}
          isOpen={isModalOpen === 'deleteProposal'}
          onClose={handleCloseModal}
          onConfirmDelete={handleConfirmDeleteProposal}
          optionToDelete={proposalInModal}
          type="proposal"
          responsiveCard={
            <ResponsiveCard
              values={proposalInModal}
              modal
              filterIncomplete={filterIncomplete}
            />
          }
        />
      )}

      {isModalOpen === 'archive' && (
        <ArchiveModal
          isOpen={isModalOpen === 'archive'}
          onClose={handleCloseModal}
          onConfirmArchive={onConfirmArchive}
          responsiveCard={
            <ResponsiveCard
              values={projectInModal}
              modal
              filterIncomplete={filterIncomplete}
            />
          }
          optionToArchive={projectInModal}
          loading={isRequesting}
        />
      )}

      {isModalOpen === 'unarchive' && (
        <ArchiveModal
          isOpen={isModalOpen === 'unarchive'}
          onClose={handleCloseModal}
          onConfirmArchive={onConfirmArchive}
          unarchive={isModalOpen === 'unarchive'}
          responsiveCard={
            <ResponsiveCard
              values={projectInModal}
              modal
              filterIncomplete={filterIncomplete}
            />
          }
          optionToArchive={projectInModal}
          loading={isRequesting}
        />
      )}
      {sizingModal && (
        <SizingModal sizing={sizingModal} setSizing={setSizingModal} />
      )}

      {isModalOpen === 'share' && (
        <ProposalShareModal
          isOpen={isModalOpen === 'share'}
          proposal={proposalInModal?.rowData}
          onClose={handleCloseModal}
          onSend={(sendBy) => {
            logEvent(proposalTracker.actions.sendProposalToCustomer, {
              sendBy,
              origin: '/projetos - Proposta',
            })
          }}
        />
      )}

      {isModalOpen === 'deleteQuotation' && (
        <QuotationDeleteModal
          quotation={selectedQuote}
          onClose={handleCloseModal}
          onSuccess={successDeleteQuotation}
          onStopDelete={checkSingleQuotation}
        />
      )}

      {isModalOpen === 'continue' && (
        <AlertModal
          isOpen={isModalOpen === 'continue'}
          onClose={handleCloseModal}
          onConfirm={handleContinueProposal}
          title="Ops! Sua proposta está incompleta. Gostaria de retomar de onde parou?"
          description="Por favor, preencha a proposta para continuar editando o projeto."
          isLoading={loadingContinue}
        />
      )}
    </>
  )
}

export default ProjectsContainer
