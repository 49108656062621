import ModalCompleteUserData from 'components/ModalCompleteUserData'
import { ProModal } from 'containers/Modals'
import { useQuotationModalContext } from 'context/QuotationModalContext'
import { useUserHasFullRegistration } from 'hooks/useUserHasFullRegistration'
import { useDispatch, useSelector } from 'react-redux'
import { setProModalClose } from 'store/actions/proModal'
import { FreePlanModal } from 'components/FreePlanModal'
import { AdvertisingModal } from 'containers/AdvertisingModal'
import { useAdvertisingModal } from 'containers/AdvertisingModal/hooks/useAdvertisingModal'
import { useFreePlanModal } from 'components/FreePlanModal/hooks/useFreePlanModal'
import { useSuccessFinancingRequestModalContext } from 'context/modals/financing/SuccessFinancingRequestModalContext'
import { SuccessRequestFinancingModal } from 'components/SuccessRequestFinancingModal'
import { QuotationModal } from 'components/QuotationModal'
import { CheckoutModal } from 'components'
import { useCheckoutModalStore } from 'store/modals/CheckoutModalStore'
import { CreateFinancingPerValueModal } from 'containers/Financing/PerValue/CreateFinancingPerValueModal'
import { useCreateFinancingPerValueModalContext } from 'containers/Financing/PerValue/CreateFinancingPerValueModal/context/CreateFinancingPerValueModalContext'
import { LeadsModal } from 'containers/SolLeadAlert/components/LeadsModal'
import { useSolLeadsModalStore } from 'containers/SolLeadAlert/components/LeadsModal/store/useSolLeadsModalStore'
import { useEffect } from 'react'
import { CreateFinancingPerQuotationModal } from 'containers/Financing/PerQuotation/CreateFinancingPerQuotationModal'
import { useCreateFinancingPerQuotationModalStore } from 'containers/Financing/PerQuotation/CreateFinancingPerQuotationModal/store'

export const GlobalModals = () => {
  const { proModalIsOpen } = useSelector((state: any) => state.ReducerProModal)
  const dispatch = useDispatch()

  const { isOpenCompleteRegistrationModal } = useUserHasFullRegistration()
  const { freePlanModalOpen } = useFreePlanModal()
  const { isQuotationModalOpen, setIsQuotationModalOpen } =
    useQuotationModalContext()

  const { advertisingModalIsOpen, waitForAdvertising, existingAdvertising } =
    useAdvertisingModal()

  const freePlanModalIsOpen = freePlanModalOpen && !existingAdvertising
  const { isFinancingPerValueModalOpen } =
    useCreateFinancingPerValueModalContext()
  const { isSuccessFinancingModalOpen } =
    useSuccessFinancingRequestModalContext()
  const isFinancingPerQuotationModalOpen =
    useCreateFinancingPerQuotationModalStore(
      (state) => state.isFinancingPerQuotationModalOpen,
    )

  const isSolLeadsModalOpen = useSolLeadsModalStore(
    (state) => state.isSolLeadsModalOpen,
  )

  const setIsSolLeadsModalOpen = useSolLeadsModalStore(
    (state) => state.setIsSolLeadsModalOpen,
  )

  const shouldOpenSolLeadsModal = Boolean(
    sessionStorage.getItem('open-sol-leads-modal'),
  )

  useEffect(() => {
    if (shouldOpenSolLeadsModal) {
      setIsSolLeadsModalOpen(shouldOpenSolLeadsModal)
      sessionStorage.removeItem('open-sol-leads-modal')
    }
  }, [shouldOpenSolLeadsModal])

  const checkoutModal = useCheckoutModalStore((state) => state)

  return (
    <>
      {proModalIsOpen && (
        <ProModal
          isOpen={proModalIsOpen}
          onClose={() => dispatch(setProModalClose())}
          showForPayment={false}
        />
      )}

      {isQuotationModalOpen && (
        <QuotationModal
          open={isQuotationModalOpen}
          onOpenChange={(value) => {
            setIsQuotationModalOpen(value)
          }}
        />
      )}

      {isOpenCompleteRegistrationModal && <ModalCompleteUserData />}

      {advertisingModalIsOpen && <AdvertisingModal />}
      {waitForAdvertising || (freePlanModalIsOpen && <FreePlanModal />)}

      {isFinancingPerValueModalOpen && <CreateFinancingPerValueModal />}
      {isFinancingPerQuotationModalOpen && <CreateFinancingPerQuotationModal />}
      {isSuccessFinancingModalOpen && <SuccessRequestFinancingModal />}

      {checkoutModal?.isOpen && (
        <CheckoutModal
          openModal={checkoutModal.isOpen}
          cotacaoId={checkoutModal?.quotationId as number}
          onCloseModal={() => {
            checkoutModal.setIsOpen(false)
            checkoutModal.reset()
          }}
        />
      )}
      {isSolLeadsModalOpen && <LeadsModal />}
    </>
  )
}
