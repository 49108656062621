import React from 'react'
import { Box } from '@material-ui/core'
import { Spacer } from '@77sol/core'
import { isNaN } from 'lodash'
import palette from 'app_palette'
import { ProposalCard, AnaliseGlobal, Crescimento } from '../components'
import styles from './styles'

const handlePercentage = (value, total) => {
  if (total === 0 || isNaN(total)) {
    return 0
  }

  return ((100 * value) / total).toFixed(1)
}

export function Infografico({ values = {} }) {
  const classes = styles()

  const total = values.global_proposal_analysis?.sent
    + values.global_proposal_analysis?.accepted
    + values.global_proposal_analysis?.paid

  const porcentagemEnviadas = handlePercentage(
    values.global_proposal_analysis?.sent,
    total,
  )
  const porcentagemAceitas = handlePercentage(
    values.global_proposal_analysis?.accepted,
    total,
  )
  const porcentagemPagas = handlePercentage(
    values.global_proposal_analysis?.paid,
    total,
  )

  return (
    <Box className={classes.container}>
      <Box className={classes.cardsContainer}>
        <AnaliseGlobal
          enviadas={porcentagemEnviadas}
          aceitas={porcentagemAceitas}
          pagas={porcentagemPagas}
        />
        <Spacer size="8" direction="both" />
        <ProposalCard
          quantidade={values.submitted_proposals}
          title="Propostas enviadas"
        />
        <Spacer size="8" direction="both" />
        <ProposalCard
          quantidade={values.accepted_proposals}
          title="Propostas aceitas"
        />
        <Spacer size="8" direction="both" />
        <ProposalCard
          quantidade={values.paid_proposals}
          title="Propostas pagas"
        />
      </Box>
      <Spacer size="8" direction="both" />
      <Box className={classes.graphContainer}>
        <Crescimento
          cardTitle="Volume de propostas"
          options={[
            {
              label: 'Enviadas',
              values: values.volume_of_proposals?.sent,
              color: palette.primary[300],
            },
            {
              label: 'Aceitas',
              values: values.volume_of_proposals?.accepted,
              color: palette.orange[300],
            },
            {
              label: 'Pagas',
              values: values.volume_of_proposals?.paid,
              color: palette.green[300],
            },
          ]}
        />
      </Box>
    </Box>
  )
}
