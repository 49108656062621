import { useToast } from '@77sol/core'
import { useMutation } from '@tanstack/react-query'
import { financingCreateSimulation } from '../services/api'
import { type INewFinancingSimulationPOSTPayload } from '../services/types'

export const useFinancingCreateSimulation = () => {
  const { onErrorOpenToast } = useToast()

  return useMutation({
    mutationFn: async (payload: INewFinancingSimulationPOSTPayload) =>
      await financingCreateSimulation.post(payload),
    onError: () =>
      onErrorOpenToast(
        'Tivemos um problema ao tentar criar um novo financimento por valor, tente novamente mais tarde.',
      ),
  })
}
