import { Button } from '@77sol-ui/atoms'
import { Tooltip } from '@77sol-ui/molecules'
import styled from 'styled-components'

export const StyledButton = styled(Button)`
  display: inline-flex;
  padding: 0 !important;
  vertical-align: top;
  margin-left: 4px;
`
export const StyledContent = styled(Tooltip.Content)`
  width: var(--radix-tooltip-content-available-width);
`
