import { PERSON_TYPE_ENUM } from 'enums/PersonTypeEnum'

export const FINANCING_TYPE = 'value'
export const LIST_CREATED_FINANCING_PATH = '/financiamentos'

export const INICIAL_VALUES_FORM = {
  client_type: PERSON_TYPE_ENUM.NATURAL,
  grace_days: '90',
  total_value: 0.0,
}
