import { Landmark, type LucideIcon } from 'lucide-react'
import { FeaturesRoutes } from 'acl/config/routes.types'
import { type ElementType } from 'react'

export interface INewSidebarNavMenuItems {
  title: string
  href: FeaturesRoutes
  icon: ElementType | LucideIcon
  signalType?: string
}

const DISTRIBUTOR_NAVBAR_MENU_ITEMS: INewSidebarNavMenuItems[] = [
  {
    title: 'Financiamentos',
    href: FeaturesRoutes.FINANCIAMENTOS,
    icon: Landmark,
  },
]

export { DISTRIBUTOR_NAVBAR_MENU_ITEMS }
