export enum FeaturesRoutes {
  CONFIGURACOES = '/configuracoes',
  MEUTIME = '/time',
  EQUIPAMENTOS = '/equipamentos',
  RANKING77 = '/rank-77',
  DASHBOARD = '/dashboard',
  PROJETOS = '/projetos',
  FINANCIAMENTOS = '/financiamentos',
  PEDIDOS = '/pedidos',
  UNIVERSIDADE77 = '/universidade-77',
  CALENDARIO = '/calendario',
  PAGAMENTOS = '/carteira/pagamentos',
}
