import {
  type ReactNode,
  createContext,
  useContext,
  useMemo,
  useState,
  useCallback,
} from 'react'

interface SuccessFinancingRequestModalData {
  isSuccessFinancingModalOpen: boolean
  setSuccessFinancingModalOpen: (redirectToPath: string) => void
  setSuccessFinancingModalClose: () => void
  redirectTo: string
}

interface ProviderProps {
  children: ReactNode
}

const SuccessFinancingRequestModalContext =
  createContext<SuccessFinancingRequestModalData>(
    {} as SuccessFinancingRequestModalData,
  )

function SuccessFinancingRequestModalProvider({ children }: ProviderProps) {
  const [isSuccessFinancingModalOpen, setIsSuccessFinancingModalOpen] =
    useState(false)

  const [redirectTo, setRedirectTo] = useState('')

  const setSuccessFinancingModalOpen = useCallback((redirectToPath: string) => {
    setIsSuccessFinancingModalOpen(true)
    setRedirectTo(redirectToPath)
  }, [])

  const setSuccessFinancingModalClose = useCallback(() => {
    setIsSuccessFinancingModalOpen(false)
  }, [])

  const contextValue = useMemo(
    () => ({
      isSuccessFinancingModalOpen,
      redirectTo,
    }),
    [isSuccessFinancingModalOpen, redirectTo],
  )

  return (
    <SuccessFinancingRequestModalContext.Provider
      value={{
        ...contextValue,
        setSuccessFinancingModalOpen,
        setSuccessFinancingModalClose,
      }}
    >
      {children}
    </SuccessFinancingRequestModalContext.Provider>
  )
}

const useSuccessFinancingRequestModalContext = () => {
  const context = useContext(SuccessFinancingRequestModalContext)
  return context
}

export {
  useSuccessFinancingRequestModalContext,
  SuccessFinancingRequestModalProvider,
}
