import { Button } from '@77sol-ui/atoms'
import { useDownloadQuotationPdf } from 'containers/EquipmentQuotationContainer/components/Tab/components/CustomizeKit/hooks/useDownloadQuotationPdf'
import { useEquipmentContainerStore } from 'containers/EquipmentQuotationContainer/store/EquipmentQuotationStore'
import { DownloadCloud } from 'lucide-react'
import { FullScreenLoading } from '../../../../../FullScreenLoading'
import { fileTracker } from 'services/tracker/events/files/trackers'
import { useAmplitude } from 'hooks/useAmplitude'
import { useDisableActionButtons } from 'containers/EquipmentQuotationContainer/hooks/useDisableActionButtons'

export function DownloadButtonQuotationPDF() {
  const { logEvent } = useAmplitude()
  const { disabled } = useDisableActionButtons()

  const quotationSelected = useEquipmentContainerStore(
    (state) => state.quotationSelected,
  )

  const { onGeneratePdfQuotation, isLoading, fileName } =
    useDownloadQuotationPdf()

  function handleDownloadPdfQuotation() {
    logEvent(fileTracker.actions.downloadFile, {
      file: 'PDF da Cotação',
      origin: '/Cotar - Personalize seus kits',
      path: '/cotar',
      fileName,
    })

    onGeneratePdfQuotation(quotationSelected?.id as number)
  }

  return (
    <>
      <Button
        variant="outline"
        onClick={handleDownloadPdfQuotation}
        disabled={disabled}
      >
        Baixar PDF
        <DownloadCloud size={16} />
      </Button>
      {isLoading && <FullScreenLoading text="Carregando PDF" />}
    </>
  )
}
