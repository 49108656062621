import { useToast } from '@77sol/core'
import { useEquipmentContainerStore } from 'containers/EquipmentQuotationContainer/store/EquipmentQuotationStore'
import { format } from 'date-fns'
import { useGeneratePdfQuotation } from 'domains/quotation/admin-pdf-generate-quotation/hooks/useGeneratePdfQuotation'
import { useMemo } from 'react'
import { saveFile } from 'utils/saveFile'

export function useDownloadQuotationPdf() {
  const { onErrorOpenToast } = useToast()

  const quotationSelected = useEquipmentContainerStore(
    (state) => state.quotationSelected,
  )

  const fileName = useMemo(() => {
    if (!quotationSelected) {
      return ''
    }

    const potency = quotationSelected.potency.toString().replace('.', '_')
    const date = format(new Date(), 'dd_MM_yyyy')
    return `${quotationSelected.id}_Cotacao_${potency}kWp_${date}`
  }, [quotationSelected])

  function downloadPdf(data: string) {
    if (!quotationSelected) return
    const file = new Blob([data], { type: 'application/pdf' })
    saveFile(file, fileName)
  }

  const { mutate: onGeneratePdfQuotation, isLoading } = useGeneratePdfQuotation(
    {
      onSuccess: downloadPdf,
      onError: (e) => {
        console.error(e)
        onErrorOpenToast('Erro ao fazer o download da cotação')
      },
    },
  )

  return { onGeneratePdfQuotation, isLoading, fileName }
}
