import { useEquipmentContainerStore } from 'containers/EquipmentQuotationContainer/store/EquipmentQuotationStore'
import { useAmplitude } from 'hooks/useAmplitude'
import { useAddEquipment } from '../../../../Equipments/hooks/useAddEquipment'
import { dimensioningTracker } from 'services/tracker/events/dimensioning/trackers'
import { quotationTracker } from 'services/tracker/events/quotation/trackers'
import { useDownloadQuotationPdf } from '../../../../../hooks/useDownloadQuotationPdf'
import { useCallback } from 'react'
import { fileTracker } from 'services/tracker/events/files/trackers'

export function useQuotationKebabMenu() {
  const { logEvent } = useAmplitude()

  const setOpenModalToChangeQuotationFreight = useEquipmentContainerStore(
    (state) => state.setOpenModalToChangeQuotationFreight,
  )

  const quotationSelected = useEquipmentContainerStore(
    (state) => state.quotationSelected,
  )

  const setOpenModalToDeleteQuotation = useEquipmentContainerStore(
    (state) => state.setOpenModalToDeleteQuotation,
  )

  const setOpenModalQuotationDiscount = useEquipmentContainerStore(
    (state) => state.setOpenModalQuotationDiscount,
  )

  const { setOpenModalToAddNewEquipment } = useAddEquipment()

  function handleChangeQuotationFreight() {
    setOpenModalToChangeQuotationFreight(true)
    logEvent(dimensioningTracker.actions.editShipment, {
      origin: '/cotar',
    })
  }

  function handleOpenAddEquipmentModal() {
    logEvent(quotationTracker.actions.addNewEquipment, {
      origin: '/cotar',
    })
    setOpenModalToAddNewEquipment(true)
  }

  const deleteQuotation = () => {
    const waitClosingMenu = () => {
      setOpenModalToDeleteQuotation(quotationSelected)
    }
    setTimeout(waitClosingMenu, 100)
  }

  function handleOpenDiscountModal() {
    setOpenModalQuotationDiscount(true)
  }

  const {
    onGeneratePdfQuotation,
    isLoading: isGeneratingQuotationPdf,
    fileName,
  } = useDownloadQuotationPdf()

  const handleGeneratePdfQuotation = useCallback(() => {
    if (!quotationSelected) return

    logEvent(fileTracker.actions.downloadFile, {
      file: 'PDF da Cotação',
      origin: '/Cotar - Personalize seus kits',
      path: '/cotar',
      fileName,
    })

    onGeneratePdfQuotation(quotationSelected.id)
  }, [quotationSelected])

  return {
    handleChangeQuotationFreight,
    handleOpenAddEquipmentModal,
    deleteQuotation,
    handleOpenDiscountModal,
    handleGeneratePdfQuotation,
    isGeneratingQuotationPdf,
  }
}
