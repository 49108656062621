import * as S from './styles'
import { Logo } from 'components/Logo'
import { Button } from '@77sol/core'
import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { InfoCard } from './components/InfoCard'
import { useAmplitude } from 'hooks/useAmplitude'
import { useFreePlanModal } from './hooks/useFreePlanModal'
import {
  CloseXIcon,
  DocumentCalculatorOutlinedIcon,
  GovernmentBalanceOutlinedIcon,
  ContractPenOutlinedIcon,
  IntegratorOutlinedIcon,
} from '@77sol/icons/dist'
import { freePlanModalTrackers } from 'services/tracker/events/freePlanModal/trackers'

export function FreePlanModal() {
  const { freePlanModalOpen, closeFreePlanModal } = useFreePlanModal()
  const { logEvent } = useAmplitude()

  const history = useHistory()

  useEffect(() => {
    if (freePlanModalOpen) {
      logEvent(freePlanModalTrackers.actions.modalAppeared)
    }
  }, [freePlanModalOpen])

  const handleCloseModal = (action: string) => {
    logEvent(freePlanModalTrackers.actions.closeModal, { action })

    closeFreePlanModal()
  }

  const handleExperimentNow = () => {
    logEvent(freePlanModalTrackers.actions.experimentNow)

    history.push('/dimensionar')
    closeFreePlanModal()
  }

  return (
    <S.Modal
      open={freePlanModalOpen}
      blurOverlay={10}
      onClose={() => {
        handleCloseModal('Saiu clicando fora do modal')
      }}
    >
      <S.Background data-testid="modal-welcome">
        <div className="topCircles">
          <div className="blueMedium" />
          <div className="purpleLarge" />
        </div>
        <div className="bottomCircles">
          <div className="lightBlueMedium" />
          <div className="lightBlueSmall" />
        </div>
        <S.Content>
          <S.Close>
            <CloseXIcon
              width="30"
              data-testid="button-close-welcome-modal"
              onClick={() => {
                handleCloseModal('Saiu clicando no X')
              }}
            />
          </S.Close>
          <S.Container>
            <S.Logo>
              <Logo.Main.White height={60} width={150} />
            </S.Logo>
            <div>
              <S.TopTitle>PLATAFORMA 77SOL</S.TopTitle>
              <S.Title>
                O MAIOR ECOSSISTEMA DE <br /> ENERGIA SOLAR DO BRASIL
              </S.Title>
            </div>
            <S.MainTitle>
              Onde você encontra tudo que precisa para seu dia a dia. E 100%
              grátis.
            </S.MainTitle>
            <S.CardsContainer>
              <InfoCard icon={<DocumentCalculatorOutlinedIcon width="22" />}>
                Dimensionamento <br /> completo
              </InfoCard>
              <InfoCard icon={<GovernmentBalanceOutlinedIcon width="22" />}>
                Tudo sobre a <br /> lei 14300
              </InfoCard>
              <InfoCard icon={<ContractPenOutlinedIcon width="22" />}>
                Personalização <br /> ilimitada de propostas
              </InfoCard>
              <InfoCard icon={<IntegratorOutlinedIcon width="22" />}>
                Cadastro ilimitado <br /> de colaboradores
              </InfoCard>
            </S.CardsContainer>
          </S.Container>
          <S.ButtonContainer>
            <Button
              variant="outlined"
              data-testid="button-experiment-now"
              onClick={handleExperimentNow}
            >
              Experimentar agora
            </Button>
          </S.ButtonContainer>
        </S.Content>
      </S.Background>
    </S.Modal>
  )
}
