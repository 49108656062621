import { Notification } from '@77sol-ui/organisms'
import { Menu } from 'lucide-react'
import { type IButtonProps } from '@77sol-ui/atoms'
import { Button } from './styles'

interface IHamburgerMenuProps {
  size?: number
  marker?: boolean
}

export function HamburgerMenu({
  size,
  marker = false,
  ...props
}: IHamburgerMenuProps & IButtonProps) {
  return (
    <Button {...props} variant="ghost" asIcon>
      <Menu size={size} />

      {marker && <Notification.Marker animate />}
    </Button>
  )
}
