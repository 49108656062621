import { Sidebar } from '@77sol-ui/organisms'
import { useSidebarDesktop } from './hooks/useSidebarDesktop'
import { DISTRIBUTOR_NAVBAR_MENU_ITEMS } from './constants/distributorNavbarItems'
import { Link } from 'react-router-dom'

export function SidebarDesktop() {
  const {
    isExpanded,
    validatePermission,
    onRedirect,
    handleControlSidebar,
    history,
    LOGO,
    signals,
  } = useSidebarDesktop()

  return (
    <Sidebar.Root expanded={isExpanded}>
      <Sidebar.Logo
        expanded={isExpanded}
        src={LOGO}
        onClick={() => history.push('/')}
      />
      <Sidebar.Trigger
        expanded={isExpanded}
        onClick={() => {
          handleControlSidebar(!isExpanded)
        }}
      />
      <Sidebar.Content>
        <Sidebar.NavRoot>
          {DISTRIBUTOR_NAVBAR_MENU_ITEMS.filter(validatePermission).map(
            (item) => {
              const Icon = item.icon
              const hasSignalization =
                signals?.[item?.signalType as keyof typeof signals]

              return (
                <Sidebar.NavItem
                  key={item.href}
                  expanded={isExpanded}
                  tooltipLabel={item.title}
                  onClick={() => {
                    onRedirect(item)
                  }}
                  active={item.href === window.location.pathname}
                  asChild
                >
                  <Link to={item.href}>
                    <Sidebar.NavIcon>
                      <Icon />
                      {hasSignalization && <Sidebar.Marker />}
                    </Sidebar.NavIcon>
                    <Sidebar.NavTitle>{item.title}</Sidebar.NavTitle>
                  </Link>
                </Sidebar.NavItem>
              )
            },
          )}
        </Sidebar.NavRoot>
      </Sidebar.Content>
    </Sidebar.Root>
  )
}
