import React, { useState, useLayoutEffect } from 'react'
import moment from 'moment'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { Box, Grid } from '@material-ui/core'
import { Spacer, Typography, useToast } from '@77sol/core'
import { Card, EmptyState } from 'components'
import { isEmpty, proposalFilterList } from 'utils'
import InfiniteScroll from 'react-infinite-scroll-component'
import API from 'api'
import styles from './styles'

export function Gestao({ kanbanList, month, year, setShouldRequest }) {
  const [lists, setLists] = useState([])

  const { onSuccessOpenToast, onErrorOpenToast } = useToast()

  const classes = styles()

  useLayoutEffect(() => {
    setLists(kanbanList)
  }, [kanbanList])
  const getProposals = async (currentPage, selectedId) => {
    try {
      const {
        data: {
          proposals: { current_page, last_page, data },
        },
      } = await API.get(`/proposal/by-status/${selectedId}`, {
        params: {
          month,
          year,
          page: currentPage,
        },
      })

      const newList = lists
      const selectedList = newList.findIndex(({ id }) => id === selectedId)
      const newItems = (newList[selectedList].items = [
        ...newList[selectedList].items,
        ...data,
      ])

      newList[selectedList] = {
        ...newList[selectedList],
        hasMore: current_page < last_page,
        month,
        year,
        page: currentPage,
        items: newItems,
      }

      setLists([...newList])
    } catch (e) {
      console.log(e)
    }
  }

  const updatePage = (hasMore, page, id) => {
    if (hasMore) {
      getProposals(page + 1, id)
    }
  }

  const handleDragEnd = async ({ source, destination }) => {
    if (!destination) {
      return
    }

    const currentList = lists
    const sourceList = currentList.find(
      (list) => list.id === source.droppableId,
    )
    const destinationList = currentList.find(
      (list) => list.id === destination.droppableId,
    )

    const [removedItem] = sourceList.items.splice(source.index, 1)

    if (source.droppableId === destination.droppableId) {
      sourceList.items.splice(destination.index, 0, removedItem)
      return setLists(currentList)
    }

    destinationList.items.splice(destination.index, 0, removedItem)
    setLists(currentList)

    const { id } = destinationList.items[destination.index]
    const status = destination.droppableId

    const proposalStatus = proposalFilterList.find(
      ({ value }) => value === status,
    )

    try {
      await API.post('/proposal/status/', {
        status: proposalStatus.slug,
        id,
      })

      onSuccessOpenToast(
        `Proposta ${id} atualizada para status ${proposalStatus.label}.`,
      )
    } catch (e) {
      onErrorOpenToast(`Não foi possível atualizar a proposta ${id}.`)
    } finally {
      setShouldRequest(true)
    }
  }
  return (
    <Box py={3} height="100%">
      <Grid className={classes.container} container spacing={2}>
        <DragDropContext onDragEnd={handleDragEnd}>
          {lists.map((list) => (
            <Droppable droppableId={list.id} key={list.id}>
              {(provided) => (
                <Grid item lg={3} md={3} xs={12}>
                  <Card
                    title={list.title}
                    headerChildren={
                      <div className={classes.ellipse}>{list.items.length}</div>
                    }
                  >
                    <Box className={classes.dragBox} ref={provided.innerRef}>
                      {isEmpty(list.items) ? (
                        <Box
                          display="flex"
                          height="100%"
                          textAlign="center"
                          justifyContent="center"
                          alignItems="center"
                        >
                          <EmptyState
                            size="small"
                            text="Você não possui nenhuma proposta."
                          />
                        </Box>
                      ) : (
                        <InfiniteScroll
                          dataLength={list.items.length}
                          next={() =>
                            updatePage(list.hasMore, list.page, list.id)
                          }
                          hasMore={list.hasMore}
                          loader={
                            <p style={{ textAlign: 'center', padding: '16px' }}>
                              <b> Carregando...</b>
                            </p>
                          }
                          height="60vh"
                        >
                          {list.items.map((task, index) => (
                            <Draggable
                              draggableId={task.id.toString()}
                              index={index}
                              key={task.id}
                            >
                              {(provided) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  <div className={classes.card}>
                                    <Typography type="link" colorWeight="800">
                                      {task.customer_name} - Proposta #{task.id}
                                    </Typography>
                                    <Spacer size="4" direction="both" />
                                    <Typography size="small" colorWeight="600">
                                      {moment(task.created_at).format(
                                        'DD/MM/YYYY - HH:mm',
                                      )}
                                    </Typography>
                                  </div>
                                </div>
                              )}
                            </Draggable>
                          ))}
                        </InfiniteScroll>
                      )}
                    </Box>
                  </Card>
                </Grid>
              )}
            </Droppable>
          ))}
        </DragDropContext>
      </Grid>
    </Box>
  )
}
