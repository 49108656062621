import PropTypes from 'prop-types'
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft'
import { Box, Hidden, useMediaQuery } from '@material-ui/core'
import { Typography, Title } from '@77sol/core'
import { MenuLeftIcon } from '@77sol/icons/dist'
import styles from './styles'
import { useSidebarMobileStore } from 'store/modals/SidebarMobileStore'
import { Logo } from 'components/Logo'

function Header({
  id = '',
  title,
  onBack,
  size,
  children = <></>,
  noMargin = false,
  sticky = false,
  half = false,
  zIndex = null,
  primary = false,
  fixedHeight = false,
}) {
  const downSm = useMediaQuery((theme) => theme.breakpoints.down('sm'))
  const classes = styles({
    size,
    onBack: !!onBack,
    noMargin,
    sticky,
    half,
    zIndex,
    downSm,
    primary,
    fixedHeight,
  })

  const setOpenSidebarMobile = useSidebarMobileStore((state) => state.setIsOpen)

  return (
    <div className={classes.container} id="headerBar">
      <Box display="flex" alignItems="center" id={id} onClick={onBack}>
        {primary && (
          <div className={classes.menuContainer}>
            <MenuLeftIcon
              onClick={() => setOpenSidebarMobile(true)}
              width="24"
            />
          </div>
        )}
        {onBack && <KeyboardArrowLeftIcon className={classes.icon} />}
        {title ? (
          <>
            {size === 'small' ? (
              <Typography>{title}</Typography>
            ) : (
              <>
                <Hidden smUp>
                  <Typography style={{ maxWidth: '250px' }}>{title}</Typography>
                </Hidden>
                <Hidden xsDown>
                  {primary ? (
                    <Title size="xsmall" weight="bold">
                      {title}
                    </Title>
                  ) : (
                    <Typography size="large">{title}</Typography>
                  )}
                </Hidden>
              </>
            )}
          </>
        ) : (
          <Logo.Main.Original height={62} width={120} />
        )}
      </Box>
      {children}
    </div>
  )
}

Header.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  onBack: PropTypes.func,
  size: PropTypes.oneOf(['small', 'default', 'huge']),
  noMargin: PropTypes.bool,
}

Header.defaultProps = {
  id: '',
  title: '',
  onBack: null,
  size: 'default',
  noMargin: false,
}

export default Header
