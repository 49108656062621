import styled from 'styled-components'

export const BannerWrapper = styled.div`
  background-color: #026cff;
  color: white;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  position: relative;

  img {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
  }

  p {
    z-index: 99;
    font-family: DM Sans;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    max-width: 460px;
    padding: 38px 45px;
  }

  @media (max-width: 580px) {
    p {
      font-size: 18px;
      max-width: 280px;
      padding: 22px;
    }
  }

  @media (max-width: 340px) {
    p {
      font-size: 17px;
      max-width: 230px;
      padding: 12px;
    }
  }
`
