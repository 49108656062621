import { makeStyles } from '@material-ui/core'

const styles = makeStyles(({ breakpoints }) => ({
  container: {
    display: 'flex',
    padding: '24px 0',
    [breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  cardsContainer: {
    width: '30%',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    [breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  graphContainer: {
    width: '70%',
    display: 'flex',
    [breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}))

export default styles
