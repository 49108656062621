import { type ReactNode } from 'react'
import * as S from './styles'

interface IInfoCardProps {
  icon: ReactNode
  children: ReactNode
}

export function InfoCard({ icon, children }: IInfoCardProps) {
  return (
    <S.Container>
      <S.PaperIcon>{icon}</S.PaperIcon>
      <S.TextIcon>{children}</S.TextIcon>
    </S.Container>
  )
}
