import React from 'react'
import PropTypes from 'prop-types'
import GaugeChart from 'react-gauge-chart'
import { Box } from '@material-ui/core'
import { Card } from 'components'
import { Typography, Title } from '@77sol/core'
import styles from './styles'

const limitPercentageInGraphic = (volumeSold) => {
  const maxKwp = 200
  const kwpPercentage = volumeSold / maxKwp
  return kwpPercentage > 1 ? 1 : kwpPercentage
}

export function MetaMensal({ volumeSold }) {
  const classes = styles()

  const kwpPercentage = limitPercentageInGraphic(volumeSold)

  return (
    <Card title="Volume vendido" fullHeight>
      <center className={classes.container}>
        <Box className={classes.chart}>
          <GaugeChart
            id="gauge-chart1"
            colors={['#032596', '#063EF9', '#99FFFF']}
            cornerRadius={0}
            arcWidth={0.1}
            arcPadding={0}
            nrOfLevels={100}
            percent={kwpPercentage}
            hideText
          />
        </Box>
        <Box>
          <Typography size="small" colorWeight="500">
            Transações efetuadas
          </Typography>
          <Box display="flex" justifyContent="center" alignItems="baseline">
            <Title weight="bold" size="small">
              {volumeSold}
            </Title>
            <Typography size="small" colorWeight="800">
              kWp
            </Typography>
          </Box>
        </Box>
      </center>
    </Card>
  )
}

MetaMensal.propTypes = {
  kwpAceitas: PropTypes.string,
}

MetaMensal.defaultProps = {
  kwpAceitas: '0',
}
