import React from 'react'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Card } from 'components'
import { Box, Hidden } from '@material-ui/core'
import { Button, Title } from '@77sol/core'

export function ProposalCard({ quantidade, title }) {
  const history = useHistory()

  return (
    <Card
      title={title}
      fullHeight
      headerChildren={
        <Hidden smDown>
          <Button
            variant="text"
            size="small"
            onClick={() => history.push('/projetos')}
          >
            Ver todas
          </Button>
        </Hidden>
      }
    >
      <>
        <Hidden smDown>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="baseline"
          >
            <Box display="flex">
              <Box minWidth="100px">
                <Title weight="xbold">{quantidade || 0}</Title>
              </Box>
            </Box>
          </Box>
        </Hidden>
        <Hidden mdUp>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            alignItems="baseline"
          >
            <Box display="flex">
              <Title>{quantidade || 0}</Title>
            </Box>
            <Box
              display="flex"
              flexWrap="wrap"
              justifyContent="space-between"
              width="100%"
            >
              <Button
                variant="text"
                size="small"
                onClick={() => history.push('/projetos')}
              >
                Ver todas
              </Button>
            </Box>
          </Box>
        </Hidden>
      </>
    </Card>
  )
}

ProposalCard.propTypes = {
  quantidade: PropTypes.number,
  title: PropTypes.string,
}
