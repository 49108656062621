import * as S from './styles'

export interface ISkeletonProps {
  height: string
  width?: string
}

export function Skeleton({ height, width = '100%' }: ISkeletonProps) {
  return <S.Skeleton height={height} width={width} />
}
