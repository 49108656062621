import { useAcl } from 'acl/hooks/useAcl'
import { DistributorContent } from './components/DistributorContent'
import { IntegratorContent } from './components/IntegratorContent'
import { PJContent } from './components/PJContent'
import { PERSON_TYPE_ENUM } from 'enums/PersonTypeEnum'
import { UserType } from 'acl/config/types'
import { type IInsuranceControl } from './types/insuranceControl'

interface IInsuranceContentProps extends IInsuranceControl {
  clientType?: PERSON_TYPE_ENUM
}

export function InsuranceContent({
  clientType,
  handleControlInsurance,
  ...props
}: IInsuranceContentProps) {
  const { userType } = useAcl()

  if (userType === UserType.DISTRIBUTOR) {
    return <DistributorContent {...props} />
  }

  if (
    userType === UserType.INTEGRATOR &&
    clientType === PERSON_TYPE_ENUM.JURIDICAL
  ) {
    return <PJContent {...props} />
  }

  return (
    <IntegratorContent
      {...props}
      handleControlInsurance={handleControlInsurance}
    />
  )
}
