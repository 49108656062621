import { type FormEvent, type ButtonHTMLAttributes } from 'react'
import * as S from './styles'
import { useCreateFinancingPerValueFormContext } from '../../../hooks/useCreateFinancingPerValueForm'

export const ClearFieldsButton = ({
  ...props
}: ButtonHTMLAttributes<HTMLButtonElement>) => {
  const {
    reset,
    getValues,
    formState: { submitCount },
    trigger,
  } = useCreateFinancingPerValueFormContext()

  const handleClearFields = (e: FormEvent) => {
    e.preventDefault()

    reset(
      {
        title: getValues('title'),
        total_value: getValues('total_value'),
        client_name: '',
        client_birth_date: '',
        client_document: '',
        client_type: getValues('client_type'),
        client_income: 0.0,
        grace_days: '90',
      },
      { keepSubmitCount: true, keepIsSubmitted: true },
    )

    if (submitCount > 0) trigger()
  }

  return (
    <S.Container type="button" onClick={handleClearFields} {...props}>
      Limpar dados
    </S.Container>
  )
}
