/* eslint-disable @typescript-eslint/promise-function-async */
import { lazy } from 'react'
import { Redirect } from 'react-router-dom'
import { type INestedRoute } from 'types/routes'
import { errorRoutes } from 'routes/errorRoutes'
import { HomeLayout } from '_Distributor/layouts/Home'

const distributorRoutes: INestedRoute[] = [
  {
    path: '/',
    exact: true,
    component: () => <Redirect to="/financiamentos" />,
  },
  { ...errorRoutes },
  {
    route: '*',
    component: HomeLayout,
    routes: [
      {
        path: [
          '/financiamentos/:tab?',
          '/financiamentos/:tab/:financingRequestId?',
          '/financiamentos/:tab/:financingRequestId/simulacao/:simulationId?',
        ],
        exact: true,
        component: lazy(() => import('../views/Financing')),
      },
      {
        path: ['/time', '/time/:tab'],
        exact: true,
        component: lazy(() => import('../views/MyTeam')),
      },
      {
        path: '/configuracoes',
        exact: true,
        component: lazy(() => import('../views/Settings')),
      },
      {
        path: '/configuracoes/:tab',
        exact: true,
        component: lazy(() => import('../views/Settings')),
      },
      {
        component: () => <Redirect to="/erro/404" />,
      },
    ],
  },
]

export default distributorRoutes
