import { type ICampaignModalsSlug } from 'domains/campaign/modals/services/types'

interface IAdvertisingRedirectProps {
  newTab: boolean
  url: string
}

export const ADVERTISING_REDIRECT_URL: Record<
  ICampaignModalsSlug,
  IAdvertisingRedirectProps
> = {
  modal_de_solleads: {
    newTab: true,
    url: 'https://forms.gle/5YmViCEhgjsED2Lu6',
  },
  modal_de_cotacao: {
    newTab: false,
    url: '/dashboard/cotar',
  },
  modal_black_friday: {
    newTab: false,
    url: '/dashboard/cotar',
  },
}
