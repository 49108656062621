import React, { Fragment } from 'react'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Card, EmptyState, Loading } from 'components'
import moment from 'moment'
import { Box, Hidden } from '@material-ui/core'
import { isEmpty } from 'utils'
import { Button, Divider, Spacer, Typography } from '@77sol/core'
import styles from './styles'
import useWindowSize from 'hooks/useWindowSize'

export function AtualizacoesPropostas({
  handleGetProposalsLog,
  updateProposals,
  isUpdatesProposalsLoading,
  hasMoreUpdateProposals,
  isRegisteredUser,
}) {
  const classes = styles()
  const history = useHistory()
  const { width } = useWindowSize()
  const isLargeScreen = width >= 1280

  const handleRedirect = (project_id, client_name, client_id) => {
    history.push({
      pathname: '/projetos/propostas',
      state: {
        projectId: project_id,
        customerName: client_name,
        customerId: client_id,
        selectedProposalId: project_id,
      },
    })
  }

  return (
    <Card title="Atualizações de Propostas" style={{ height: '100%' }}>
      <div>
        <Loading
          isLoading={isUpdatesProposalsLoading}
          minHeight={isLargeScreen ? '400px' : '200px'}
        >
          {isEmpty(updateProposals) ? (
            <EmptyState
              text="Não há nenhuma atualização"
              size="small"
              minHeight={isLargeScreen ? '400px' : '200px'}
            />
          ) : (
            <InfiniteScroll
              dataLength={updateProposals.length}
              next={handleGetProposalsLog}
              hasMore={hasMoreUpdateProposals}
              className="infinite-scroll"
              loader={
                <p style={{ textAlign: 'center', paddingTop: '16px' }}>
                  <b> Carregando... </b>
                </p>
              }
              height="400px"
              endMessage={
                <p style={{ textAlign: 'center', paddingTop: '16px' }}>
                  <b> Não há mais atualizações. </b>
                </p>
              }
            >
              {updateProposals.map(
                (
                  { updated_at, client_name, client_id, action, project_id },
                  index,
                ) => (
                  <Fragment key={`${action + project_id + updated_at + index}`}>
                    <Box className={classes.content}>
                      <Hidden smDown>
                        <Box flex={1} mr={2}>
                          <Typography size="xsmall" colorWeight="600">
                            {moment(updated_at).format('DD/MM/YYYY')}
                          </Typography>
                          <Typography size="xsmall" colorWeight="600">
                            {moment(updated_at).format('HH:mm:ss')}
                          </Typography>
                        </Box>
                      </Hidden>
                      <Box className={classes.boxLabels}>
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          width="100%"
                          alignItems="center"
                        >
                          <Typography size="small">{client_name}</Typography>
                          <Hidden mdUp>
                            <Typography size="xsmall" colorWeight="600">
                              {moment(updated_at, 'YYYY-MM-DD hh:mm:ss')
                                .lang('pt-br')
                                .fromNow()}
                            </Typography>
                          </Hidden>
                        </Box>
                        <Typography size="xsmall" colorWeight="400">
                          {action}
                        </Typography>
                      </Box>
                      <Hidden mdUp>
                        <Spacer size="8" direction="both" />
                      </Hidden>
                      <Box flex={2} textAlign="right" width="100%">
                        <Button
                          size="small"
                          variant="outlined"
                          fullWidth
                          onClick={() =>
                            handleRedirect(project_id, client_name, client_id)
                          }
                        >
                          Ver Proposta
                        </Button>
                      </Box>
                    </Box>
                    <Divider />
                  </Fragment>
                ),
              )}
            </InfiniteScroll>
          )}
        </Loading>
      </div>
    </Card>
  )
}

AtualizacoesPropostas.propTypes = {
  className: PropTypes.string,
}
