import { Suspense, useState, useEffect, useCallback } from 'react'
import { LinearProgress, Hidden } from '@material-ui/core'
import { withSize } from 'react-sizeme'
import FormatAlignLeftOutlinedIcon from '@material-ui/icons/FormatAlignLeftOutlined'
import { Switch, Prompt, useLocation } from 'react-router-dom'
import { BrowserView } from 'react-device-detect'
import { RouteWithSubRoutes } from 'components'
import { useSelector } from 'react-redux'
import { SaveDefaultComponent } from 'views/DimensionarV2/components/StepFour/components'
import { ProModal } from 'containers/Modals'
import { useToast } from '@77sol/core'
import { hasDifferentProvidersInQuotation } from 'utils/functions'
import { DiscardProposalModal } from 'components/DiscardProposalModal'
import API from 'api'

import { ROUTES_TO_OPEN_PROMPT } from './constants'
import { useStyles } from './styles'
import { SaveAndQuitProjectModal } from 'components/SaveAndQuitProjectModal'
import { useByPassSaveProposal } from './hooks/useByPassSaveProposal'
import { Sidebar } from 'components/Sidebar'
import useWindowSize from 'hooks/useWindowSize'

function Dashboard({ route }) {
  const { byPassSaveProposal, handleByPassSaveProposal } =
    useByPassSaveProposal()

  const location = useLocation()
  const { onSuccessOpenToast } = useToast()
  const classes = useStyles()

  const proposal = useSelector((state) => state.Reducer1)

  const [isInDimensionar, setIsInDimensionar] = useState(false)
  const [openModalSavedConfigs, setOpenModalSavedConfigs] = useState(false)
  const [isOpenCancelProposalModal, setIsOpenCancelProposalModal] =
    useState(false)
  const [isOpenCancelQuotationModal, setIsOpenCancelQuotationModal] =
    useState(false)
  const hasProviderErrorInQuotation = hasDifferentProvidersInQuotation(
    proposal?.quotes,
  )
  const [isCancelingQuotationOrProject, setIsCancelingQuotationOrProject] =
    useState(false)
  const [locationToRedirectUser, setLocationToRedirectUser] = useState('')
  const [statusMenu, setStatusMenu] = useState(true)

  const userCanCancelProposal = proposal?.quotes?.length > 0

  const handleMenuClose = () => {
    setStatusMenu(false)
  }

  const handleMenuOpen = () => {
    if (statusMenu) {
      setStatusMenu(false)
    } else {
      setStatusMenu(true)
    }
  }

  const handleCheckLeavingDimensionar = useCallback(
    (newValue) => () => {
      if (!newValue && isInDimensionar) {
        onSuccessOpenToast('Projeto salvo')
      }
    },
    [isInDimensionar],
  )

  const renderRoutesEffect = route?.map((appRoute) => (
    <RouteWithSubRoutes key={appRoute?.path} {...appRoute} />
  ))

  useEffect(() => {
    const page = window.location.pathname

    if (page === '/dimensionar') {
      setIsInDimensionar(true)
    } else if (page === '/dashboard/c/') {
      handleCheckLeavingDimensionar(true)
      setIsInDimensionar(false)
    } else {
      handleCheckLeavingDimensionar(false)
      setIsInDimensionar(false)
    }
  }, [renderRoutesEffect, handleCheckLeavingDimensionar])

  const [isProPlanModalOpen, setIsProPlanModalOpen] = useState(false)

  function handleBackToHome() {
    try {
      localStorage.removeItem('reduxStore')
      window.location.pathname = locationToRedirectUser
    } catch (error) {
      throw new Error('Erro ao redirecionar usuário')
    }
  }

  async function cancelProposalOrQuotationRequisition() {
    setIsCancelingQuotationOrProject(true)
    try {
      if (proposal?.proposal?.id) {
        await API.put(`/proposal/cancel/${proposal?.proposal?.id}`)
      }
    } catch (error) {
      throw new Error('Houve um erro ao finalizar a requisição')
    }
  }

  async function handleCancelProposalOrQuotation() {
    setIsCancelingQuotationOrProject(true)
    try {
      await cancelProposalOrQuotationRequisition()
      window.location.pathname = locationToRedirectUser
      setIsCancelingQuotationOrProject(false)
    } catch (error) {
      setIsCancelingQuotationOrProject(false)
    }
  }

  function handleDisplayPrompt(path) {
    setLocationToRedirectUser(path)

    if (byPassSaveProposal) {
      handleByPassSaveProposal(false)

      return true
    }

    if (path === '/dashboard/c/' || location?.state?.saveAndQuitByHeader) {
      return true
    }

    if (!userCanCancelProposal) {
      return true
    }

    if (location.state?.isQuotation) {
      setIsOpenCancelQuotationModal(true)
    } else {
      setIsOpenCancelProposalModal(true)
    }
    return false
  }

  const { width } = useWindowSize()
  const IS_MOBILE = width < 1280

  return (
    <div className={classes.root}>
      <Prompt
        when={ROUTES_TO_OPEN_PROMPT.includes(window.location.pathname)}
        message={(locationState) => handleDisplayPrompt(locationState.pathname)}
      />

      <div className={classes.container}>
        <Hidden xsUp>
          <BrowserView>
            <FormatAlignLeftOutlinedIcon
              onClick={() =>
                statusMenu ? handleMenuClose() : handleMenuOpen()
              }
              className={classes.formatAlignIcon}
            />
          </BrowserView>
        </Hidden>

        {!IS_MOBILE ? <Sidebar.Desktop /> : <Sidebar.Mobile />}

        <main className={classes.content}>
          <Suspense fallback={<LinearProgress />}>
            <Switch>
              {route?.map((mappedRoute) => (
                <RouteWithSubRoutes key={mappedRoute?.path} {...mappedRoute} />
              ))}
            </Switch>
          </Suspense>
        </main>
      </div>

      {openModalSavedConfigs && (
        <SaveDefaultComponent
          openModalSavedConfigs={openModalSavedConfigs}
          setOpenModalSavedConfigs={setOpenModalSavedConfigs}
        />
      )}

      {isProPlanModalOpen && (
        <ProModal
          isOpen={isProPlanModalOpen}
          onClose={() => setIsProPlanModalOpen(false)}
        />
      )}

      {isOpenCancelQuotationModal && (
        <DiscardProposalModal
          isOpenModal={isOpenCancelQuotationModal}
          setIsOpenModal={setIsOpenCancelQuotationModal}
          hasProviderError={hasProviderErrorInQuotation}
          handleCancel={handleCancelProposalOrQuotation}
          isCancelingQuotationOrProject={isCancelingQuotationOrProject}
          handleContinue={handleBackToHome}
          isQuotation
        />
      )}

      {isOpenCancelProposalModal && (
        <SaveAndQuitProjectModal
          open={isOpenCancelProposalModal}
          onOpenChange={() => setIsOpenCancelProposalModal(false)}
          onClose={() => setIsOpenCancelProposalModal(false)}
          onSuccess={handleBackToHome}
          hasProviderError={hasProviderErrorInQuotation}
          handleCancelProject={handleCancelProposalOrQuotation}
        />
      )}
    </div>
  )
}

export default withSize()(Dashboard)
