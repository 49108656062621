import { Radio } from '@77sol-ui/atoms'
import { useCreateFinancingPerValueFormContext } from '../../../hooks/useCreateFinancingPerValueForm'
import { type PersonType } from '../../../types'
import { PERSON_TYPE_ENUM } from 'enums/PersonTypeEnum'

export const RadioPersonType = () => {
  const {
    register,
    formState: { errors, isDirty, isSubmitted },
    setValue,
    watch,
    trigger,
  } = useCreateFinancingPerValueFormContext()

  const choosedPersonType = watch('client_type')

  const onValueChange = (value: PersonType) => {
    setValue('client_type', value)
    if (isSubmitted || isDirty) trigger()
  }

  return (
    <Radio.Root>
      <Radio.Group
        iconType="success"
        {...(register('client_type'),
        {
          error: Boolean(errors.client_type?.message),
          onValueChange,
          defaultValue: choosedPersonType,
        })}
      >
        <Radio.Item
          name={PERSON_TYPE_ENUM.NATURAL}
          value={PERSON_TYPE_ENUM.NATURAL}
        >
          Pessoa Física
        </Radio.Item>
        <Radio.Item
          name={PERSON_TYPE_ENUM.JURIDICAL}
          value={PERSON_TYPE_ENUM.JURIDICAL}
        >
          Pessoa Jurídica
        </Radio.Item>
      </Radio.Group>
    </Radio.Root>
  )
}
