import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(({ breakpoints }) => ({
  graphLegend: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    [breakpoints.down('sm')]: {
      width: '100%',
      alignItems: 'center',
    },
  },
}))

export default useStyles
