import { makeStyles } from '@material-ui/core'

const styles = makeStyles(({ palette, breakpoints }) => ({
  dragBox: {
    height: '60vh',
    overflowY: 'auto',
    [breakpoints.down('sm')]: {
      minHeight: '150px',
      height: '100%',
    },
  },
  ellipse: {
    minWidth: '32px',
    minHeight: '32px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    color: palette.white,
    backgroundColor: palette.primary[300],
  },
  card: {
    padding: '16px 20px',
    cursor: 'grabbing',
    backgroundColor: palette.grayscale[200],
    borderRadius: '8px',
    marginBottom: '16px',
    borderLeft: `4px solid ${palette.grayscale[400]}`,
  },
}))

export default styles
