import { palette } from '@77sol/styles'
import { CheckIcon } from '@77sol/icons/dist'
import { Box, useMediaQuery } from '@material-ui/core'
import { Button, Modal, Spacer, Title, Typography } from '@77sol/core'
import { useSuccessFinancingRequestModalContext } from 'context/modals/financing/SuccessFinancingRequestModalContext'
import { useHistory, useLocation } from 'react-router-dom'

export function SuccessRequestFinancingModal() {
  const downXs = useMediaQuery((theme) => theme.breakpoints.down('xs'))

  const history = useHistory()
  const location = useLocation()

  const {
    isSuccessFinancingModalOpen,
    setSuccessFinancingModalClose,
    redirectTo,
  } = useSuccessFinancingRequestModalContext()

  const handleCloseModal = () => {
    setSuccessFinancingModalClose()

    if (location.pathname === redirectTo) {
      return history.go(0)
    }

    history.push({
      pathname: redirectTo,
      state: {
        isFromHeader: true,
      },
    })
  }

  return (
    <Modal
      open={isSuccessFinancingModalOpen}
      onClose={handleCloseModal}
      ariaHideApp={false}
    >
      <Box>
        <Box
          display="flex"
          alignItems="center"
          flexDirection={downXs ? 'column' : 'row'}
        >
          <CheckIcon
            color={palette.primary[300]}
            width={downXs ? '30px' : '20px'}
          />
          <Spacer size="8" direction={downXs ? 'horizontal' : 'vertical'} />
          <Title
            size="xsmall"
            color="primary"
            colorWeight="300"
            style={{ textAlign: downXs && 'center' }}
          >
            Simulação solicitada com sucesso!
          </Title>
        </Box>
        <Spacer size="12" direction="horizontal" />
        <Typography
          size="small"
          style={{
            maxWidth: !downXs ? '550px' : 'auto',
            textAlign: downXs && 'center',
          }}
        >
          Sua simulação foi solicitada e está sendo analisada pelo nosso time.
          Você pode acompanhar o status pela página de financiamentos.
        </Typography>
        <Spacer size="18" direction="horizontal" />
        <Box
          display="flex"
          flexDirection={downXs ? 'column' : 'row'}
          justifyContent={downXs ? 'center' : 'end'}
        >
          <Button
            size="small"
            color="primary"
            variant="contained"
            onClick={handleCloseModal}
          >
            Confirmar
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}
