import React from 'react'
import { Doughnut } from 'react-chartjs-2'
import { Card } from 'components'
import { Box } from '@material-ui/core'
import { Status } from '@77sol/core'

export function AnaliseGlobal({ enviadas, aceitas, pagas }) {
  const handleAllZeros =
    enviadas === 0 && aceitas === 0 && pagas === 0
      ? [33, 33, 33]
      : [enviadas, aceitas, pagas]

  const state = {
    labels: ['Enviadas', 'Aceitas', 'Pagas'],
    datasets: [
      {
        label: 'Propostas',
        backgroundColor: ['#2E4CCD', '#FA9917', '#54D169'],
        borderWidth: 1,
        cutout: '80%',
        borderRadius: 30,
        data: handleAllZeros,
        hoverOffset: 4,
      },
    ],
  }

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    cutoutPercentage: 80,
    legend: {
      display: false,
    },
    tooltips: {
      enabled: true,
    },
  }

  return (
    <Card title="Análise Global das Propostas">
      <Box display="flex" alignItems="center">
        <Box width="100px" height="100px">
          <Doughnut data={state} options={options} />
        </Box>
        <Box display="flex" flexDirection="column" paddingLeft={4}>
          <Status label="Enviadas" ellipseColor="primary" />
          <Status label="Aceitas" ellipseColor="orange" />
          <Status label="Pagas" ellipseColor="green" />
        </Box>
      </Box>
    </Card>
  )
}
