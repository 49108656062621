import { useEffect, useState } from 'react'
import { differenceInDays, format } from 'date-fns'
import { useAcl } from 'acl/hooks/useAcl'
import { useLocalStorage } from 'hooks/useLocalStorage'

export function useFreePlanModal() {
  const [freePlanModalOpen, setFreePlanModalOpen] = useState(false)
  const { userType, user } = useAcl()
  const userId = user[0]?.id
  const [storedDate, setStoredDate] = useLocalStorage<Record<
    string,
    string
  > | null>('dateViewFreePlanModal', null)

  const currentDate = format(new Date().setHours(0, 0, 0, 0), 'yyyy-MM-dd')

  const closeFreePlanModal = () => {
    setFreePlanModalOpen(false)
    setStoredDate((value) => {
      return { ...value, ...{ [userId]: currentDate } }
    })
  }

  function checkIfTheUserWasCreatedLessThan30daysAgo() {
    const userCreated = new Date(user?.[0]?.created_at)
    const today = new Date(currentDate)

    const numberOfDays = differenceInDays(today, userCreated) || 0

    return numberOfDays <= 30
  }

  const userWasCreatedLessThan30daysAgo =
    checkIfTheUserWasCreatedLessThan30daysAgo()

  const userIsNotDistributor = userType !== 'distributor'

  const userHasAlreadyTakenFirstAction = user[0]?.first_action_taken

  const userHasAlreadySeenTheModalToday = currentDate === storedDate?.[userId]

  useEffect(() => {
    if (
      userWasCreatedLessThan30daysAgo &&
      userIsNotDistributor &&
      !userHasAlreadyTakenFirstAction &&
      !userHasAlreadySeenTheModalToday
    ) {
      setFreePlanModalOpen(true)
    }
  }, [
    userWasCreatedLessThan30daysAgo,
    userIsNotDistributor,
    userHasAlreadyTakenFirstAction,
    userHasAlreadySeenTheModalToday,
  ])

  return {
    freePlanModalOpen,
    closeFreePlanModal,
  }
}
