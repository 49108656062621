import styled from 'styled-components'
import { Modal as ModalCore } from '@77sol/core'

export const Modal = styled(ModalCore)`
  color: #fff;
  background: #0071f7;

  overflow: hidden;
  border-radius: 12px;

  padding: 0 !important;

  @media (max-height: 660px) {
    max-height: 80vh;
    overflow-y: auto;
  }
`

export const Content = styled.div`
  padding: 32px;
  position: relative;
  z-index: 1;
`

export const Background = styled.div`
  position: relative;
  overflow: hidden;

  .topCircles,
  .bottomCircles {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 0;
  }

  .blueMedium {
    position: absolute;
    inset: -83px 482px 257px -67px;

    width: 337px;
    height: 337px;
    border-radius: 337px;
    background-color: #063ef9;
    filter: blur(56px);
  }

  .purpleLarge {
    position: absolute;
    inset: -129px -65px 210px 387px;

    width: 430px;
    height: 430px;
    border-radius: 430px;
    background-color: #7302dc;
    filter: blur(113px);
  }

  .lightBlueMedium {
    position: absolute;
    inset: 345px 552px -142px -108px;

    width: 308px;
    height: 308px;
    border-radius: 308px;
    background-color: #0cc;
    filter: blur(102.5px);
  }

  .lightBlueSmall {
    position: absolute;
    inset: 429px 45px -101px 524px;

    width: 183px;
    height: 183px;
    border-radius: 183px;
    background-color: #0ff;
    filter: blur(86px);
  }

  @media (max-width: 768px) {
    .blueMedium {
      display: none;
    }

    .purpleLarge {
      inset: 75px 159px 145px -247px;
    }

    .lightBlueMedium {
      display: none;
    }

    .lightBlueSmall {
      inset: 464px 282px 3px -123px;
    }
  }
`

export const Close = styled.div`
  position: relative;

  svg {
    cursor: pointer;
    position: absolute;
    right: 0;
  }
`

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 12px;
`

export const Logo = styled.div`
  display: grid;
  place-content: center;
`

export const TopTitle = styled.h2`
  font-size: 16px;
  font-weight: 400;
  line-height: 56px;
  letter-spacing: 3.6px;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 12px;
  }
`

export const Title = styled.h1`
  font-size: 28px;
  font-weight: 800;
  line-height: 40px;
  letter-spacing: -0.5px;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 20px;
    line-height: 32px;
  }
`

export const MainTitle = styled.h3`
  font-size: 15px;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 0.75px;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 13px;
    line-height: 22px;
  }
`

export const CardsContainer = styled.div`
  display: grid;
  grid-template-columns: 170px 170px 170px 170px;
  gap: 10px;

  @media (max-width: 768px) {
    grid-template-columns: 150px 150px;
  }
`

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 32px;

  button {
    width: 284px;
    border-radius: 76px;
    border: none !important;
    box-shadow: none !important;
    background-color: #fff !important;
  }
`
