import React from 'react'
import PropTypes from 'prop-types'
import { Card } from 'components'
import { makeStyles } from '@material-ui/core/styles'

import { Line } from 'react-chartjs-2'

const useStyles = makeStyles(() => ({
  root: {
    position: 'relative',
    height: '100%',
    minHeight: '300px',
    width: '100%',
  },
}))

export function Crescimento({ options, cardTitle }) {
  const classes = useStyles()

  const graphOptions = {
    scales: {
      xAxes: [
        {
          gridLines: {
            display: true,
          },
        },
      ],
      yAxes: [
        {
          // stacked: true,
          gridLines: {
            display: true,
          },
          ticks: {
            beginAtZero: true,
            // Return an empty string to draw the tick line but hide the tick label
            // Return `null` or `undefined` to hide the tick line entirely
            userCallback(value) {
              // Convert the number to a string and splite the string every 3 charaters from the end
              value = value.toString()
              value = value.split(/(?=(?:...)*$)/)

              // Convert the array to a string and format the output
              value = value.join('.')
              return `${value}`
            },
          },
        },
      ],
    },
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
    tooltips: {
      enabled: true,
    },
  }

  const state = {
    labels: ['1º Sem', '2º Sem', '3º Sem', '4º Sem'],
    datasets: options.map(({ label, color, values = [] }) => ({
      label,
      fill: false,
      lineTension: 0.1,
      backgroundColor: color,
      borderColor: color,
      borderCapStyle: 'butt',
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: 'miter',
      pointBorderColor: 'rgba(46,91,255,1)',
      pointBackgroundColor: '#fff',
      pointBorderWidth: 1,
      pointHoverRadius: 5,
      pointHoverBackgroundColor: 'rgba(46,91,255,1)',
      pointHoverBorderColor: 'rgba(46,91,255,1)',
      pointHoverBorderWidth: 2,
      pointRadius: 1,
      pointHitRadius: 10,
      data: [values[0], values[1], values[2], values[3]],
    })),
  }

  return (
    <Card title={cardTitle} fullHeight fullWidth>
      <div className={classes.root}>
        <Line data={state} height={100} options={graphOptions} />
      </div>
    </Card>
  )
}

Crescimento.propTypes = {
  className: PropTypes.string,
}
