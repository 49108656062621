import { QUERY_KEYS } from 'services/constant'
import API from 'api'
import {
  type NewOrderStorePOSTPayload,
  type NewOrderStorePOSTResponse,
} from './types'

export const lojaNovoPedidoLoja = {
  post: async (payload: NewOrderStorePOSTPayload) => {
    return await API.post<NewOrderStorePOSTResponse>(
      QUERY_KEYS.LOJA.NOVO_PEDIDO_LOJA,
      {
        ...payload.order,
        billingAddress: payload.billingAddress,
        deliveryAddress: payload.deliveryAddress,
      },
    ).then((res) => res.data)
  },
}
