import { Tooltip } from '@77sol/core'
import { DownloadCloud, Loader2 } from 'lucide-react'
import { useDownloadQuotationPdf } from 'containers/EquipmentQuotationContainer/components/Tab/components/CustomizeKit/hooks/useDownloadQuotationPdf'
import { fileTracker } from 'services/tracker/events/files/trackers'
import { useAmplitude } from 'hooks/useAmplitude'
import { useDisableActionButtons } from 'containers/EquipmentQuotationContainer/hooks/useDisableActionButtons'

interface DownloadQuotationButtonProps {
  quotationId: number
}

export function DownloadQuotationButton({
  quotationId,
}: DownloadQuotationButtonProps) {
  const { logEvent } = useAmplitude()
  const { disabled } = useDisableActionButtons()
  const { onGeneratePdfQuotation, isLoading, fileName } =
    useDownloadQuotationPdf()

  function handleDownloadPdfQuotation() {
    logEvent(fileTracker.actions.downloadFile, {
      file: 'PDF da Cotação',
      origin: '/Cotar - Compare seus kits',
      path: '/cotar',
      fileName,
    })

    onGeneratePdfQuotation(quotationId)
  }

  return (
    <Tooltip title="Baixar cotação">
      <button
        onClick={handleDownloadPdfQuotation}
        disabled={isLoading || disabled}
      >
        {isLoading ? (
          <Loader2 size={24} className="atm-animate-spinSlow" />
        ) : (
          <DownloadCloud />
        )}
      </button>
    </Tooltip>
  )
}
