import { makeStyles } from '@material-ui/core/styles'
import { effects } from '@77sol/styles'

const useStyles = makeStyles(({ breakpoints, palette }) => ({
  container: {
    backgroundColor: palette.white,
    boxShadow: effects.boxShadow.small,
    padding: '24px',
    borderRadius: '8px',
  },
  content: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [breakpoints.down('lg')]: {
      alignItems: 'flex-start',
      flexDirection: 'column',
    },
  },
  infoBox: {
    display: 'flex',
    [breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  buttonBox: {
    display: 'flex',
    [breakpoints.down('lg')]: {
      paddingTop: '16px',
      flexDirection: 'row-reverse',
    },
  },
}))

export default useStyles
