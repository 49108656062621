import { useCallback, useState } from 'react'
import {
  type DefaultValuesForm,
  type ICreateFinancingPerValueFormData,
} from '../components/FormManager/types'
import { useUserHasFullRegistration } from 'hooks/useUserHasFullRegistration'
import { useFinancingCreateSimulation } from 'domains/financing/create-simulation'
import { type INewFinancingSimulationPOSTPayload } from 'domains/financing/create-simulation/services/types'
import { useCreateFinancingPerValueModalContext } from '../context/CreateFinancingPerValueModalContext'
import { useSuccessFinancingRequestModalContext } from 'context/modals/financing/SuccessFinancingRequestModalContext'
import { useAmplitude } from 'hooks/useAmplitude'
import { financingTracker } from 'services/tracker/events/financing/trackers'
import { format } from 'date-fns'
import {
  FINANCING_TYPE,
  INICIAL_VALUES_FORM,
  LIST_CREATED_FINANCING_PATH,
} from '../constants'
import { PERSON_TYPE_ENUM } from 'enums/PersonTypeEnum'
import { useByPassSaveProposal } from 'layouts/Dashboard/hooks/useByPassSaveProposal'

export function useCreateFinancingPerValueModal() {
  const { mutateAsync, isError, isLoading } = useFinancingCreateSimulation()
  const { validateUserRegistration } = useUserHasFullRegistration()

  const [requestFallback, setRequestFallback] = useState<
    DefaultValuesForm | ICreateFinancingPerValueFormData
  >(INICIAL_VALUES_FORM)

  const { setFinancingPerValueModalClose } =
    useCreateFinancingPerValueModalContext()
  const { setSuccessFinancingModalOpen } =
    useSuccessFinancingRequestModalContext()

  const { logEvent } = useAmplitude()
  const { handleByPassSaveProposal } = useByPassSaveProposal()

  const manageRequestFallback = useCallback(
    (data: ICreateFinancingPerValueFormData) => {
      const { client_birth_date, ...rest } = data

      setRequestFallback({
        ...rest,
        client_birth_date: format(client_birth_date as Date, 'dd-MM-yyyy'),
      })
    },
    [],
  )

  const handleCreateFinancingByValueRequest = useCallback(
    async (data: ICreateFinancingPerValueFormData) => {
      manageRequestFallback(data)

      const {
        client_birth_date,
        integrator,
        client_type,
        client_income,
        total_value,
        is_insurance = false,
        ...rest
      } = data

      const clientType =
        client_type === PERSON_TYPE_ENUM.NATURAL
          ? 'Pessoa Física'
          : 'Pessoa Jurídica'

      logEvent(financingTracker.actions.financingCreated, {
        financingType: 'Valor',
        clientType,
      })

      if (!validateUserRegistration()) return

      const payload = {
        ...rest,
        client_type,
        client_birth_date: format(client_birth_date as Date, 'yyyy-MM-dd'),
        origin: FINANCING_TYPE,
        client_income: client_income.toFixed(2),
        total_value: total_value.toFixed(2),
        ...(data.integrator?.id && {
          user_id: Number(data.integrator?.id),
        }),
        ...(client_type === PERSON_TYPE_ENUM.NATURAL && {
          is_insurance,
        }),
      }

      mutateAsync(payload as INewFinancingSimulationPOSTPayload).then(() => {
        handleByPassSaveProposal(true)
        setFinancingPerValueModalClose()
        setSuccessFinancingModalOpen(LIST_CREATED_FINANCING_PATH)
      })
    },
    [
      logEvent,
      mutateAsync,
      manageRequestFallback,
      validateUserRegistration,
      setSuccessFinancingModalOpen,
      setFinancingPerValueModalClose,
    ],
  )

  return {
    isError,
    isLoading,
    handleCreateFinancingByValueRequest,
    requestFallback,
  }
}
