import React from 'react'
import { Grid, Box, Hidden } from '@material-ui/core'
import palette from 'app_palette'
import globalConfigs from 'globalConfigs'
import { CommunicationBanner } from 'components/CommunicationBanner'
import styles from './styles'
import {
  AtualizacoesPropostas,
  MapaAtividades,
  MetaMensal,
  ProposalCard,
  NivelAtual,
  VolumeNecessario,
  Crescimento,
} from '../components'

export function Geral({
  values = {},
  handleGetProposalsLog,
  isUpdatesProposalsLoading,
  updateProposals,
  hasMoreUpdateProposals,
  isRegisteredUser,
}) {
  const classes = styles()

  return (
    <Box py={2}>
      <Grid container className={classes.box}>
        <Grid
          container
          item
          spacing={1}
          xs={12}
          md={4}
          lg={6}
          className={classes.box}
        >
          <Hidden smDown>
            <Grid item md={12} lg={6}>
              <MetaMensal volumeSold={values.volume_sold} />
            </Grid>
          </Hidden>
          <Grid
            container
            item
            spacing={1}
            xs={12}
            md={12}
            lg={6}
            className={classes.box}
            style={{ padding: 0 }}
          >
            <Grid item xs={6} md={12}>
              <ProposalCard
                quantidade={values.submitted_proposals}
                title="Propostas Enviadas"
              />
            </Grid>
            <Grid item xs={6} md={12}>
              <ProposalCard
                quantidade={values.accepted_proposals}
                title="Propostas Aceitas"
              />
            </Grid>
          </Grid>
          <Hidden mdUp>
            <Grid item xs={12}>
              <MetaMensal volumeSold={values.volume_sold} />
            </Grid>
          </Hidden>
          <Grid
            container
            item
            spacing={1}
            md={12}
            lg={12}
            className={classes.box}
            style={{ padding: 0 }}
          >
            <Hidden only="md">
              <Grid item xs={12} lg={6}>
                <MapaAtividades
                  submittedProposals={values.submitted_proposals}
                  acceptedProposals={values.accepted_proposals}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <VolumeNecessario
                  soldVolume={values.volume_sold}
                  goal={values.goal}
                />
              </Grid>
            </Hidden>
            <Grid item xs={12} md={12} lg={12}>
              <NivelAtual
                plan={values.user_plan}
                totalDiscont={values.current_level}
                nextPlan={values.user_next_plan}
                goalAchieved={values.goal_achieved}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          item
          spacing={1}
          xs={12}
          md={8}
          lg={6}
          className={classes.box}
          style={{ padding: 0 }}
        >
          {globalConfigs.appinfo.show_campaigns.supportMaterials && (
            <Grid item xs={12}>
              <CommunicationBanner />
            </Grid>
          )}

          <Grid item xs={12}>
            <AtualizacoesPropostas
              handleGetProposalsLog={handleGetProposalsLog}
              updateProposals={updateProposals}
              isUpdatesProposalsLoading={isUpdatesProposalsLoading}
              hasMoreUpdateProposals={hasMoreUpdateProposals}
              isRegisteredUser={isRegisteredUser}
            />
          </Grid>
          <Hidden only={['sm', 'xs', 'lg', 'xl']}>
            <Grid item xs={6}>
              <MapaAtividades
                submittedProposals={values.submitted_proposals}
                acceptedProposals={values.accepted_proposals}
              />
            </Grid>
            <Grid item xs={6}>
              <VolumeNecessario
                requiredVolume={values.required_volume}
                soldVolume={values.volume_sold}
                goal={values.goal}
              />
            </Grid>
          </Hidden>
        </Grid>
        <Grid
          container
          item
          xs={12}
          spacing={1}
          className={classes.box}
          style={{ padding: 0 }}
        >
          <Grid item xs={12}>
            <Crescimento
              cardTitle="Crescimento em transações efetuadas"
              options={[
                {
                  label: 'Mês atual (kWp)',
                  values: values.growth_in_transactions_made?.current,
                  color: palette.primary[300],
                },
              ]}
            />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}
