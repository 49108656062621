import BannerDesktop from './assets/banner-desktop.svg'
import BannerMobile from './assets/banner-mobile.svg'
import * as S from './styles'

export function Banner() {
  return (
    <S.BannerWrapper>
      <p>Parcelas que substituem a conta de luz do seu cliente</p>

      <picture>
        <source srcSet={BannerMobile} media="(max-width: 1100px)" />
        <img
          src={BannerDesktop}
          alt="Imagem de um telhado com painéis soláres"
        />
      </picture>
    </S.BannerWrapper>
  )
}
