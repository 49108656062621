import { Info } from 'lucide-react'
import { StyledButton, StyledContent } from './styles'
import { Tooltip as PrimitiveTooltip } from '@77sol-ui/molecules'

import { useTooltip } from './hooks/useTooltip'

export function Tooltip() {
  const {
    open,
    setOpen,
    isMobile,
    handleOpenByClick,
    handleCloseByOutsideClick,
    containerElement,
    closeTooltipWhenScrolling,
  } = useTooltip()

  return (
    <PrimitiveTooltip.Provider delayDuration={50}>
      <PrimitiveTooltip.Root
        open={open}
        onOpenChange={setOpen}
        delayDuration={50}
      >
        <PrimitiveTooltip.Trigger asChild>
          <StyledButton
            variant="text"
            asIcon
            onClick={handleOpenByClick}
            data-testid="insurance-alert-tooltip-trigger"
            onScrollCapture={closeTooltipWhenScrolling}
          >
            <Info size={16} />
          </StyledButton>
        </PrimitiveTooltip.Trigger>
        <PrimitiveTooltip.Portal container={containerElement}>
          <StyledContent
            align="center"
            maxWidth="560px"
            side={isMobile ? 'top' : 'bottom'}
            sideOffset={5}
            variant="white"
            collisionBoundary={containerElement}
            collisionPadding={10}
            data-testid="insurance-alert-tooltip-content"
            onPointerDownOutside={handleCloseByOutsideClick}
          >
            Em caso de perda de renda, o seguro cobre até 4 parcelas do
            financiamento (limitado ao valor de parcela de R$ 1.500,00). Em caso
            de invalidez permanente total por acidente, o financiamento é
            quitado (limitado a R$ 75.000,00). Em caso de morte por qualquer
            causa, o financiamento é quitado (limitado a R$ 75.000,00).
            <PrimitiveTooltip.Arrow variant="white" />
          </StyledContent>
        </PrimitiveTooltip.Portal>
      </PrimitiveTooltip.Root>
    </PrimitiveTooltip.Provider>
  )
}
