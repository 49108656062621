export const ROUTES_TO_SHOW_READER = [
  '/dashboard',
  '/dashboard/c/',
  '/calendario',
  '/projetos',
  '/equipamentos',
  '/financiamentos',
  '/pedidos',
  '/carteira/pagamentos',
  '/configuracoes',
  '/configuracoes/empresa',
  '/time',
]

export const INITIALLY_OPEN_DRAWER_ON_ROUTES = [
  '/dashboard',
  '/dashboard/c/',
  '/rank-77',
]

export const ROUTES_TO_OPEN_PROMPT = ['/dimensionar', '/projetos/propostas']
