import { Modal } from '@77sol-ui/molecules'
import { Content } from './components/Content'
import { LoadingAnimated } from './components/LoadingAnimated'
import { type IQuotationModalProps } from './types'
import { useQuotationModal } from './hooks/useQuotationModal'

export function QuotationModal({ open, onOpenChange }: IQuotationModalProps) {
  const { isLoading, isError, requestFallback, handleCreateQuotation } =
    useQuotationModal({ onOpenChange })

  return (
    <Modal.Root open={open} onOpenChange={onOpenChange}>
      <Modal.Portal>
        <Modal.Overlay />

        {!isLoading && (
          <Content
            onCreateQuotation={handleCreateQuotation}
            defaultValues={requestFallback}
          />
        )}

        {isLoading && !isError && <LoadingAnimated />}
      </Modal.Portal>
    </Modal.Root>
  )
}
