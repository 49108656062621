import React from 'react'
import PropTypes from 'prop-types'
import { Box } from '@material-ui/core'
import { Spacer, Status, Typography } from '@77sol/core'
import { Card } from 'components'
import { Doughnut } from 'react-chartjs-2'

const options = {
  responsive: true,
  maintainAspectRatio: false,
  cutoutPercentage: 80,
  legend: {
    display: false,
  },
  tooltips: {
    enabled: true,
  },
}

export function VolumeNecessario({ soldVolume, goal }) {
  const remaining = goal - soldVolume
  const positiveRemainingValueOrZero = remaining > 0 ? remaining : 0

  const state = {
    labels: ['Alcançado', 'Restante'],
    datasets: [
      {
        backgroundColor: ['#063EF9', '#E5EBFE'],
        data: [goal, positiveRemainingValueOrZero],
      },
    ],
  }

  return (
    <Card title="Volume necessário" fullHeight>
      <Box display="flex" alignItems="center" justifyContent="flex-start">
        <Box width="130px" height="130px">
          <Doughnut data={state} options={options} width={130} height={130} />
        </Box>
        <Spacer size="16" direction="both" />
        <Box display="flex" flexDirection="column" justifyContent="center">
          <Typography size="xsmall" colorWeight="500">
            Propostas
          </Typography>
          <Box py={1}>
            <Status label="Alcançado" ellipseColor="primary" />
            <Status label="Restante" ellipseColor="grayscale" />
          </Box>
        </Box>
      </Box>
    </Card>
  )
}

VolumeNecessario.propTypes = {
  className: PropTypes.string,
}
