import { useAmplitude } from 'hooks/useAmplitude'
import { useHistory } from 'react-router-dom'
import { useEquipmentContainerStore } from '../store/EquipmentQuotationStore'
import useObserveQuery from 'hooks/useObserverQuery'
import { type IDimensioningGETResponse } from 'domains/dimensioning/services/types'
import { GET_DIMENSIONING } from 'domains/dimensioning/constants/endpointKeys'
import { useCancelProposalMutation } from 'domains/quotation/proposal-cancel/hooks/useCancelProposalMutation'
import { useCallback } from 'react'
import { useToast } from '@77sol/core'
import { quotationTracker } from 'services/tracker/events/quotation/trackers'

export function useCancelProposal() {
  const { logEvent } = useAmplitude()
  const history = useHistory()

  const { onErrorOpenToast } = useToast()

  const dimensioningId = useEquipmentContainerStore(
    (state) => state.dimensioningId,
  )

  const resetStates = useEquipmentContainerStore((state) => state.reset)

  const { data } = useObserveQuery<IDimensioningGETResponse>([
    GET_DIMENSIONING,
    dimensioningId,
  ])

  const { mutate: cancelProposal, isLoading } = useCancelProposalMutation({
    onSuccess: () => {
      history.push('/dashboard')
      resetStates()
    },
    onError: () => {
      onErrorOpenToast('Erro ao cancelar as cotações!')
    },
  })

  const onCancelProposal = useCallback(() => {
    logEvent(quotationTracker.actions.cancelQuotation, {
      origin: '/Cotar',
    })
    if (!data?.proposal.id) return

    cancelProposal(data.proposal.id)
  }, [data, cancelProposal])

  return { onCancelProposal, isLoading }
}
